import kennelImg from "assets/images/breeder.png";
import servProvImg from "assets/images/service_provider.png";
import defaultDog from "assets/images/dog.jpg";
import defaultCat from "assets/images/cat.jpg";
import defaultImg from "assets/images/gao.svg";
import { useState } from "react";
import { LuArrowLeft, LuArrowRight } from "react-icons/lu";
import styles from "./imageslider.module.scss";
type ObjectFitType = "fill" | "contain" | "cover" | "none" | "scale-down";
type ImageSliderProps = {
  imageUrls: string[];
  imageAlt: string;
  objectFit?: ObjectFitType;
  businessType?: string;
  pet?: string;
  borderRadius?: string;
  small?: boolean;
};
export default function ImageSlider({
  imageUrls,
  imageAlt,
  objectFit,
  borderRadius,
  small = false,
  businessType,
  pet,
}: ImageSliderProps) {
  const [imageIndex, setImageIndex] = useState<number>(0);
  const isDefaultImg = imageUrls.length ? false : true;
  let defaultImg: any;

  if (isDefaultImg) {
    if (pet) {
      pet == "dog" ? (defaultImg = defaultDog) : (defaultImg = defaultCat);
    } else {
      businessType == "kennel"
        ? (defaultImg = kennelImg)
        : (defaultImg = servProvImg);
    }
  }
  const showNextImage = () => {
    setImageIndex((index) => {
      if (index === imageUrls.length - 1) return 0;
      return index + 1;
    });
  };
  const showPrevImage = () => {
    setImageIndex((index) => {
      if (index === 0) return imageUrls.length - 1;
      return index - 1;
    });
  };

  return (
    <div className={styles.box}>
      {(isDefaultImg || imageUrls.length <= 1) && (
        <div className={styles.imgSlider}>
          <img
            className={styles.img}
            style={{
              objectFit: isDefaultImg ? "fill" : objectFit,
              borderRadius: borderRadius,
            }}
            src={imageUrls[0] || defaultImg}
            alt={imageAlt}
          />
        </div>
      )}

      {!isDefaultImg && imageUrls.length > 1 && (
        <>
          <div className={styles.imgSlider}>
            {imageUrls.map((url) => (
              <img
                key={url}
                className={styles.img}
                src={url || defaultImg}
                style={{
                  translate: `${-100 * imageIndex}%`,
                  objectFit: objectFit,
                  borderRadius: borderRadius,
                }}
                alt={imageAlt}
              />
            ))}
          </div>
          {small ? (
            <>
              <button
                className={styles.smallBtn}
                style={{ left: "-2px" }}
                onClick={showPrevImage}
              >
                <LuArrowLeft />
              </button>
              <button
                className={styles.smallBtn}
                style={{ right: "-2px" }}
                onClick={showNextImage}
              >
                <LuArrowRight />
              </button>
            </>
          ) : (
            <>
              <button
                className={styles.btn}
                style={{ left: "10px" }}
                onClick={showPrevImage}
              >
                <LuArrowLeft />
              </button>
              <button
                className={styles.btn}
                style={{ right: "10px" }}
                onClick={showNextImage}
              >
                <LuArrowRight />
              </button>
            </>
          )}
        </>
      )}
    </div>
  );
}
