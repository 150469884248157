import React, { useEffect } from "react";
import styles from "./notlogged.module.scss";
import Page from "../../components/page/Page";
import Wrapper from "../../components/wrapper/Wrapper";
import Header from "../../components/header/Header";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";

export default function NotLogged() {
  const { checkIsLoggedIn, isLoggedIn } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    checkIsLoggedIn();
    if (isLoggedIn === true) {
      navigate("/gaoanimalcenter");
    }
  });

  return (
    <Page>
      <Wrapper>
        <div className={styles.box}>
          <Header>Opcja dostępna po zalogowaniu</Header>
          <div className={styles.row}>
            <div className={styles.box__buttonBox}>
              <Link to="/login" className={styles.box__button}>
                <span>Z</span>
                <span>a</span>
                <span>l</span>
                <span>o</span>
                <span>g</span>
                <span>u</span>
                <span>j</span> <span>s</span>
                <span>i</span>
                <span>ę</span>
              </Link>
            </div>
            <div className={styles.box__buttonBox}>
              <div className={styles.box__buttonBox}>
                <Link className={styles.box__button} to="/register">
                  <span>Z</span>
                  <span>a</span>
                  <span>r</span>
                  <span>e</span>
                  <span>j</span>
                  <span>e</span>
                  <span>s</span>
                  <span>t</span>
                  <span>r</span>
                  <span>u</span>
                  <span>j</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    </Page>
  );
}
