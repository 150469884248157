import React from "react";
import styles from "./inputField.module.scss";

interface InputFieldInterface {
  label: string;
  name: string;
  value: string | number | undefined;
  onChange: (event: any) => void;
  error: string;
  type: string;
  required: boolean;
  length?: number;
  autoComplete?: string;
  max?: string;
}

const InputField = ({
  label,
  name,
  value,
  onChange,
  error,
  type,
  required,
  length,
  autoComplete,
  max,
}: InputFieldInterface) => {
  return (
    <div className={styles.formInputBox}>
      <input
        type={type}
        name={name}
        value={value}
        onChange={onChange}
        required={required}
        className={`${styles.formInput} ${error ? styles.formInput__error : ""}`}
        placeholder=" "
        maxLength={length ? length : 100}
        autoComplete={autoComplete}
        max={max}
      />
      <label className={styles.formLabelName}>
        {label} {required && "(wymagane)"}
      </label>
      {error && <p className={styles.errorMessage}>{error}</p>}
    </div>
  );
};

export default InputField;
