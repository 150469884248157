import { useEffect, useRef, useState } from "react";
import styles from "./changePicture.module.scss";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { url } from "../../../url";
import { useUser } from "../../../hooks/useUser";
import { setegid } from "process";
import { LuCamera } from "react-icons/lu";
import { UndoRounded } from "@mui/icons-material";
import Compressor from "compressorjs";
import Loading from "components/loading/BetterLoading";
interface ChangeEmailProps {
  isOpen: boolean;
  onClose: () => void;
  label: string;
}

export default function ChangeEmail({
  isOpen,
  onClose,
  label,
}: ChangeEmailProps) {
  const popupRef = useRef<HTMLDivElement>(null);
  const [emailChangeError, setEmailChangeError] = useState<String>("");
  const { userData, setDataChangeStatus, setIsError, getUser, getUserData } =
    useUser();
  const [imgError, setImgError] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [img, setImg] = useState<string>("");
  const [file, setFile] = useState<File>();
  const photo = userData.photo ? userData.photo : "";

  const onImageChange = (e: any) => {
    setImgError("");
    const file = e.target.files[0];

    if (!file) {
      return;
    }
    setFile(file);
    setImg(URL.createObjectURL(file));
  };

  const changeDataUser = async (file: any) => {
    const formatedData = new FormData();
    formatedData.append("file", file);

    let response;
    try {
      response = await axios.post(url + "/user/photo", formatedData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
      });
    } catch (error: any) {
      response = error.response;
      console.log(response);
    }
    console.log(response.data);
    return response.data;
  };

  const deleteUserPicture = async () => {
    let response;
    try {
      response = await axios.delete(url + "/user/photo", {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
      });
    } catch (error: any) {
      response = error.response;
      console.log(response);
    }
    console.log(response.data);
    return response.data;
  };

  const handleSubmit = (event: any) => {
    setImgError("");
    event.preventDefault();

    setIsLoading(true);
    mutate(file, {
      onSuccess: () => {
        setIsLoading(false);
        onClose();
      },
      onError: () => {
        setIsLoading(false);
      },
    });
    // onClose();
  };
  const { mutate } = useMutation({
    mutationFn: changeDataUser,
    onSuccess: (data: any) => {
      if (data.message === "Cant find user") {
        setDataChangeStatus("Nie ma tekiego użytkownika");
        setIsError(true);
        return;
      } else if (data.message === "Profile photo updated successfully") {
        setDataChangeStatus("Zdjęcie zostało zmienione pomyślnie");
        setIsError(false);
        getUser();
        setImgError("");
        setFile(undefined);
        setImg("");
        return;
      }
    },
  });

  const onClick = (event: any) => {
    setImgError("");
    mutateDeletePicture();
    event.preventDefault();
    onClose();
  };

  const { mutate: mutateDeletePicture } = useMutation({
    mutationFn: deleteUserPicture,
    onSuccess: (data: any) => {
      if (data.message === "Cant find user") {
        setDataChangeStatus("Nie ma tekiego użytkownika");
        setIsError(true);
        return;
      } else if (data.message === "Profile photo deleted successfully") {
        setDataChangeStatus("Zdjęcie zostało usunięte");
        setIsError(false);
        setImgError("");
        setFile(undefined);
        setImg("");
        getUser();
        return;
      }
    },
  });

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target as Node)
      ) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, onClose]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setEmailChangeError("");
    }, 3000);
    return () => clearTimeout(timer);
  }, [emailChangeError]);

  if (!isOpen) return null;

  return (
    <div className={styles.changeName} ref={popupRef}>
      <p style={{ color: "green" }}>{emailChangeError}</p>
      <div className={styles.changeName__mainContainer}>
        <div className={styles.changeName__topContainer}>
          <p>{label}</p>
          <span className={styles.changeName__close} onClick={onClose}>
            &times;
          </span>
        </div>
        {/* no profile picture and no selected image */}
        {isLoading ? (
          <div className={styles.changeName__loading}>
            <Loading />
          </div>
        ) : (
          <>
            {photo === "" && img === "" && (
              <>
                <LuCamera className={styles.changeName__icon} />
                <div className={styles.changeName__inputImgContainer}>
                  <label className={styles.changeName__fileInputLabel}>
                    <input
                      className={styles.changeName__fileInput}
                      type="file"
                      max={1}
                      onChange={onImageChange}
                      accept="image/png, image/jpg, image/jpeg"
                    />
                    Dodaj zdjęcie
                  </label>
                </div>
              </>
            )}
            {/* no profile picture and selected image */}
            {photo === "" && img !== "" && (
              <>
                <img src={img} className={styles.changeName__profileImg}></img>
                <div className={styles.changeName__inputImgContainer}>
                  <label className={styles.changeName__fileInputLabel}>
                    <input
                      className={styles.changeName__fileInput}
                      type="file"
                      max={1}
                      onChange={onImageChange}
                      accept="image/png, image/jpg, image/jpeg"
                    />
                    Wybierz inne zdjęcie
                  </label>
                  <button
                    className={styles.changeName__saveChangesButton}
                    onClick={handleSubmit}
                  >
                    Zapisz zmiany
                  </button>
                </div>
              </>
            )}
            {/* has profile picture and no selected new image */}
            {photo !== "" && img === "" && (
              <>
                <img
                  src={photo}
                  className={styles.changeName__profileImg}
                ></img>
                <div className={styles.changeName__inputImgContainer}>
                  <label className={styles.changeName__fileInputLabel}>
                    <input
                      className={styles.changeName__fileInput}
                      type="file"
                      max={1}
                      onChange={onImageChange}
                      accept="image/png, image/jpg, image/jpeg"
                    />
                    Wybierz inne zdjęcie
                  </label>
                  <button
                    className={styles.changeName__deleteFiles}
                    onClick={onClick}
                  >
                    Usuń zdjęcie
                  </button>
                  <button
                    className={styles.changeName__saveChangesButton}
                    onClick={handleSubmit}
                  >
                    Zapisz zmiany
                  </button>
                </div>
              </>
            )}
            {/* has profile picture and selected new image */}
            {photo !== "" && img !== "" && (
              <>
                <img src={img} className={styles.changeName__profileImg}></img>
                <div className={styles.changeName__inputImgContainer}>
                  <label className={styles.changeName__fileInputLabel}>
                    <input
                      className={styles.changeName__fileInput}
                      type="file"
                      multiple
                      max={1}
                      onChange={onImageChange}
                      accept="image/png, image/jpg, image/jpeg"
                    />
                    Wybierz inne zdjęcie
                  </label>
                  <button
                    className={styles.changeName__saveChangesButton}
                    onClick={handleSubmit}
                  >
                    Zapisz zmiany
                  </button>
                </div>
              </>
            )}
          </>
        )}
      </div>
      {/* </>
      )} */}
    </div>
  );
}
