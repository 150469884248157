import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import Page from "../../components/page/Page";
import Wrapper from "../../components/wrapper/Wrapper";
import Header from "../../components/header/Header";
import InputField from "../../components/forms/InputField/InputField";
import styles from "./resetPassword.module.scss";
import { toast, ToastContainer } from "react-toastify";
import React from "react";
import { url } from "../../url";

export default function ResetPassword() {
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])/;

  const formik = useFormik({
    initialValues: {
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      newPassword: Yup.string()
        .required("Pole nie może byc puste")
        .min(8, "Hasło jest za krótkie")
        .matches(
          passwordRegex,
          "Hasło musi zawierać dużą, małą literę, cyfrę oraz znak specjalny.",
        ),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("newPassword")], "Hasła nie są takie same")
        .required("Pole nie może byc puste"),
    }),
    onSubmit: (values) => {
      console.log("onSubmit triggered"); // Log to ensure the function is triggered
      const password = { newPassword: values.newPassword };
      const token = window.location.pathname.split("/").pop();

      console.log("Submitting the form with:", password); // Log form values
      axios
        .post(`${url}/auth/reset-password/${token}`, password)
        .then((response: any) => {
          console.log("API response:", response); // Log the API response
          toast.success("Hasło zostało zmienione");
          setTimeout(() => {
            window.location.href = "/login";
          }, 2000);
        })
        .catch((error: any) => {
          console.log("API error:", error); // Log any errors
          toast.error("Link wygasł");
        });
    },
  });

  return (
    <Page>
      <Wrapper>
        <div className={styles.passwordReset}>
          <Header>Resetowanie hasła</Header>
          <ToastContainer
            toastStyle={{
              width: "32px", // Adjust the width
              height: "32px", // Adjust the height
            }}
          ></ToastContainer>
          <form
            onSubmit={formik.handleSubmit}
            className={styles.passwordReset__container}
          >
            <InputField
              label={"Hasło"}
              name={"newPassword"}
              value={formik.values.newPassword}
              onChange={formik.handleChange}
              error={
                formik.touched.newPassword && formik.errors.newPassword
                  ? formik.errors.newPassword
                  : ""
              }
              type={"password"}
              required
              length={128}
            />
            <InputField
              label={"Powtórz hasło"}
              name={"confirmPassword"}
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              error={
                formik.touched.confirmPassword && formik.errors.confirmPassword
                  ? formik.errors.confirmPassword
                  : ""
              }
              type={"password"}
              required
              length={128}
            />
            <button
              className={styles.passwordReset__saveChangesButton}
              type="submit"
            >
              Zresetuj hasło
            </button>
          </form>
        </div>
      </Wrapper>
    </Page>
  );
}
