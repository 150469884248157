import { useEffect, useRef, useState } from "react";
import styles from "./deleteBusiness.module.scss";
import InputField from "../InputField/InputField";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { url } from "../../../url";
import { useUser } from "../../../hooks/useUser";
import { useNavigate } from "react-router-dom";

interface DeleteBusinessProps {
  isOpen: boolean;
  onClose: () => void;
  label: string;
}

export default function DeleteBusiness({
  isOpen,
  onClose,
  label,
}: DeleteBusinessProps) {
  const { setDataChangeStatus, dataChangeStatus, setIsError, getUser } =
    useUser();
  const popupRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const changeDataUser = async () => {
    let response;
    console.log("test");
    try {
      response = await axios.delete(url + "/user/business", {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      });
    } catch (error: any) {
      response = error.response;
      console.log(response);
    }
    return response.data;
  };

  const handleSubmit = (event: any) => {
    mutate();
    event.preventDefault();
    onClose();
  };

  const { mutate } = useMutation({
    mutationFn: changeDataUser,
    onSuccess: async (data: any) => {
      console.log(data);
      setDataChangeStatus("Działalność została usunięta");
      setIsError(false);
      getUser(); //#4 change context
      return;
    },
  });

  if (!isOpen) return null;

  return (
    <form onSubmit={handleSubmit}>
      <div className={styles.changeName} ref={popupRef}>
        <div className={styles.changeName__mainContainer}>
          <div className={styles.changeName__topContainer}>
            <p>{label}</p>
            <span className={styles.changeName__close} onClick={onClose}>
              &times;
            </span>
          </div>
          <button
            className={styles.changeName__saveChangesButton}
            onClick={onClose}
          >
            Nie chcę usuwać
          </button>
          <button className={styles.changeName__deleteAccount} type="submit">
            Usuń działalność
          </button>
        </div>
      </div>
    </form>
  );
}
