import React, { useState } from "react";
import DeleteAccount from "./DeleteAccount";
import styles from "./deleteAccount.module.scss";

interface DeleteAccountButtonProps {
  label: string;
}

export default function DeleteAccountButton({
  label,
}: DeleteAccountButtonProps) {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  return (
    <>
      <button onClick={togglePopup} className={styles.popupButton}>
        Usuń konto
      </button>
      <DeleteAccount isOpen={isPopupOpen} onClose={closePopup} label={label} />
    </>
  );
}
