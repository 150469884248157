import React, { useState } from "react";
import ChangeNameNick from "./ChangeNameNick";
import styles from "./changeNameNick.module.scss";

interface ChangeNameNickButtonProps {
  label: string;
}

export default function ChangeNameNickButton({
  label,
}: ChangeNameNickButtonProps) {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  return (
    <div>
      <button onClick={togglePopup} className={styles.popupButton}>
        Edytuj
      </button>
      <ChangeNameNick
        isOpen={isPopupOpen}
        onClose={closePopup}
        label={`Edytuj ${label}`}
      />
    </div>
  );
}
