import axios from "axios";
import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from "react";
import { url } from "../url";

interface AuthContextType {
  userToken: string | null | undefined;
  isLoggedIn: boolean;
  login: (token: string) => void;
  logout: () => void;
  checkIsLoggedIn: () => void;
  setIsLoggedIn: Dispatch<SetStateAction<boolean>>;
}

const defaultAuthContext: AuthContextType = {
  userToken: null,
  isLoggedIn: false,
  login: () => {},
  logout: () => {},
  checkIsLoggedIn: () => {},
  setIsLoggedIn: () => false,
};

const AuthContext = createContext<AuthContextType>(defaultAuthContext);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [userToken, setUserToken] = useState<string | null | undefined>(
    localStorage.getItem("token"),
  );

  const checkIsLoggedIn = () => {
    const token = localStorage.getItem("token");
    console.log(token);
    if (token === "true") {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  };

  const login = (token: string) => {
    localStorage.setItem("token", token);
    setUserToken(token);
    setIsLoggedIn(true);
    return;
  };

  const logout = async () => {
    let response;
    try {
      response = await axios.post(
        url + "/auth/logout",
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        },
      );
      console.log(response);
    } catch (error: any) {
      response = error.response;
      console.log(response);
    }
    localStorage.setItem("token", "false");
    setUserToken("false");
    setIsLoggedIn(false);
    return;
  };

  return (
    <AuthContext.Provider
      value={{
        userToken,
        isLoggedIn,
        login,
        logout,
        checkIsLoggedIn,
        setIsLoggedIn,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
