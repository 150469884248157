import { useEffect, useState } from "react";
import { LuBone, LuHelpingHand, LuStore } from "react-icons/lu";
import { Link, useLocation } from "react-router-dom";
import styles from "./businessnav.module.scss";
import Infobox from "components/Infobox/Infobox";
export default function BusinessNav() {
  const location = useLocation();
  const pathname = location.pathname;
  const [show, setShow] = useState<boolean>(true);
  const [lastScrollY, setLastScrollY] = useState<number>(0);
  const controlNavbar = () => {
    if (window.scrollY > lastScrollY) setShow(false);
    else setShow(true);
    setLastScrollY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", controlNavbar);
    return () => {
      window.removeEventListener("scroll", controlNavbar);
    };
  }, [lastScrollY]);
  return (
    <div className= {styles.nav__center}>
    <Infobox/>
     <nav
      className={`${styles.nav} ${show ? styles.noTransformY : styles.transformY}`}
    >
      <ul className={styles.nav__box}>
        <li className={styles.nav__linkBox}>
          <Link
            className={`${styles.nav__linkIcon} ${pathname === "/kennels" ? styles.nav__active : ""}`}
            to="/kennels"
          >
            <LuBone />
          </Link>
          <Link
            className={`${styles.nav__link} ${pathname === "/kennels" ? styles.nav__active : ""}`}
            to="/kennels"
          >
            Hodowle
          </Link>
        </li>
        <li className={styles.nav__linkBox}>
          <Link
            className={`${styles.nav__linkIcon} ${pathname === "/pet_services" ? styles.nav__active : ""}`}
            to="/pet_services"
          >
            <LuHelpingHand />
          </Link>
          <Link
            className={`${styles.nav__link} ${pathname === "/pet_services" ? styles.nav__active : ""}`}
            to="/pet_services"
          >
            Usługodawcy
          </Link>
        </li>
        <li className={styles.nav__linkBox}>
          <Link
            className={`${styles.nav__linkIcon} ${pathname === "/sellers" ? styles.nav__active : ""}`}
            to="/sellers"
          >
            <LuStore />
          </Link>
          <Link
            className={`${styles.nav__link} ${pathname === "/sellers" ? styles.nav__active : ""}`}
            to="/sellers"
          >
            Sprzedawcy
          </Link>
        </li>        
      </ul>
    </nav>
    </div>
  );
}
