import { Link, useLocation } from "react-router-dom";
import styles from "./dropdown.module.scss";

interface DropdownInterface {
  items: string[];
  paths: string[];
  drop: boolean;
}

export default function Dropdown({ items, paths, drop }: DropdownInterface) {
  const location = useLocation();
  const pathname = location.pathname;

  return (
    <div className={`${styles.box} ${drop ? styles.open : ""}`}>
      <ul className={styles.box__menuBox}>
        {items.map((item, index) => (
          <li className={styles.box__linkBox} key={index}>
            <Link
              className={`${styles.box__link} ${
                pathname === paths[index] ? styles.box__linkActive : ""
              }`}
              to={paths[index]}
            >
              {item}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}
