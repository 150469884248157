import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import InputField from "../../components/forms/InputField/InputField";
import Header from "../../components/header/Header";
import Page from "../../components/page/Page";
import RelativeInfobox from "../../components/RealtiveInfoBox/RelativeInfobox";
import Wrapper from "../../components/wrapper/Wrapper";
import { useUser } from "../../hooks/useUser";
import { url } from "../../url";
import cat from "./img/cat.png";
import dog from "./img/dog.png";
import styles from "./registration.module.scss";
import Loading from "components/loading/BetterLoading";

interface FormData {
  name: string;
  dateOfBirth: string;
  chipNumber: string;
  origin: string;
  vet: string;
}

const addObject = async ({
  newObject,
  objectType,
  token,
  imgs,
}: {
  newObject: any;
  objectType: string;
  token: string;
  imgs: any;
}) => {
  if (newObject["chipNumber"] === "" || undefined) {
    delete newObject.chipNumber;
  }
  const formatedData = new FormData();
  for (let i = 0; i < imgs.length; i++) {
    formatedData.append("files", imgs[i]);
  }
  formatedData.append("data", JSON.stringify(newObject));
  formatedData.forEach((value, key) => {
    console.log(key + ", " + value);
  });
  let response;

  try {
    response = await axios.post(url + `/${objectType}/addID`, formatedData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      withCredentials: true,
    });
  } catch (error: any) {
    response = error.response;
    console.log(response);
  }
  return response.data;
};

export default function Registration() {
  const [formData, setFormData] = useState<FormData>({
    name: "",
    dateOfBirth: "",
    chipNumber: "",
    origin: "",
    vet: "",
  });
  const [images, setImages] = useState<File[]>([]);
  const [chipError, setChipError] = useState<string>("");
  const [imgError, setImgError] = useState<string>("");
  const [duplicateError, setDuplicateError] = useState<string>("");
  const { setDataChangeStatus, setIsError } = useUser();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const location = useLocation();
  const pathname = location.pathname.slice(14);
  const callName = pathname;
  const headerName = pathname === "dog" ? "psa" : "kota";
  const imgType = pathname === "dog" ? dog : cat;
  const buttonName: any = {
    dog: "psa",
    cat: "kota",
  };

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const onImageChange = (e: any) => {
    if (Array.from(e.target.files).length > 3) {
      e.preventDefault();
      setImgError("Maksymalna ilość zdjęć to 3");
      setDataChangeStatus("Maksymalna ilość zdjęć to 3");
      setIsError(true);
      return;
    }
    setImgError("");
    setImages([...e.target.files]);
  };

  const onClick = (e: any) => {
    setImages([]);
    setDataChangeStatus("Usunięto dodane pliki");
    setIsError(false);
  };

  const { mutate } = useMutation({
    mutationKey: [`${pathname}`],
    mutationFn: addObject,
    onSuccess: (data: any) => {
      console.log(data.message);
      if (data.status === 500 && data.message.includes("duplicate")) {
        setDuplicateError(
          `Już istnieje taki ${pathname === "dog" ? "pies" : "kot"}`,
        );
        setDataChangeStatus(
          `Już istnieje taki ${pathname === "dog" ? "pies" : "kot"}`,
        );
        setIsError(true);
      } else if (data.status === 500) {
        console.log("something wrongs");
      } else {
        setDataChangeStatus(`Udało się zarejestrować ${buttonName[pathname]}`);
        setIsError(false);
        setFormData((prevFormData) => ({
          ...formData,
          name: "",
          dateOfBirth: "",
          chipNumber: "",
          origin: "",
          vet: "",
        }));
        setImages([]);
      }
    },
  });

  const handleFormSubmit = async (event: any) => {
    setImgError("");
    setChipError("");
    setDuplicateError("");
    const token = localStorage.getItem("token");
    if (formData.chipNumber?.length && formData.chipNumber.length !== 15) {
      setChipError("Numer czipu musi mieć 15 cyfr");
      setDataChangeStatus("Numer czipu musi mieć 15 cyfr");
      setIsError(true);
      event.preventDefault();
      return;
    }
    if (images.length > 3) {
      setImgError("Maksymalna ilość zdjęć to 3");
      setDataChangeStatus("Maksymalna ilość zdjęć to 3");
      setIsError(true);
      event.preventDefault();
      return;
    }
    if (token) {
      setIsLoading(true);
      mutate(
        {
          newObject: formData,
          objectType: callName,
          token: token,
          imgs: images,
        },
        {
          onSettled: () => {
            setIsLoading(false);
          },
        },
      );
    }
    event.preventDefault();
  };

  const today_date = new Date().toISOString().split("T")[0];

  return (
    <Page>
      <Wrapper>
        <div className={styles.box}>
          <div className={styles.box__headerBox}>
            <Header>Rejestracja</Header>
          </div>
          <div className={styles.box__cardBox}>
            <div className={styles.box__formBox}>
              <form className={styles.box__form} onSubmit={handleFormSubmit}>
                <h2 className={styles.box__formTitle}>
                  Rejestracja {headerName}
                </h2>
                <InputField
                  label={"Imię"}
                  name={"name"}
                  value={formData.name}
                  onChange={handleChange}
                  error={""}
                  type={"text"}
                  required
                  length={40}
                />
                <InputField
                  label={"Data urodzenia"}
                  name={"dateOfBirth"}
                  value={formData.dateOfBirth}
                  onChange={handleChange}
                  error={""}
                  type={"date"}
                  required
                  length={10}
                  max={today_date}
                />
                <InputField
                  label={"Numer chipu"}
                  name={"chipNumber"}
                  value={formData.chipNumber}
                  onChange={handleChange}
                  error={chipError}
                  type={"text"}
                  required={false}
                  length={15}
                />
                <div className={styles.box__inputBox}>
                  <InputField
                    label={"Pochodzenie"}
                    name={"origin"}
                    value={formData.origin}
                    onChange={handleChange}
                    error={""}
                    type={"text"}
                    required
                    length={58}
                  />
                  <InputField
                    label={"Weterynarz"}
                    name={"vet"}
                    value={formData.vet}
                    onChange={handleChange}
                    error={""}
                    type={"text"}
                    required
                    length={50}
                  />
                </div>
                <div className={styles.box__bottomContentBox}>
                  <div className={styles.box__buttonBox}>
                    <button
                      className={styles.box__button}
                      type="submit"
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <Loading></Loading>
                      ) : (
                        <>
                          <span>Z</span>
                          <span>a</span>
                          <span>r</span>
                          <span>e</span>
                          <span>j</span>
                          <span>e</span>
                          <span>s</span>
                          <span>t</span>
                          <span>r</span>
                          <span>u</span>
                          <span>j</span>{" "}
                          {buttonName[pathname]
                            .split("")
                            .map((letter: any, i: number) => (
                              <span key={i}>{letter}</span>
                            ))}
                        </>
                      )}
                    </button>
                  </div>
                  <div className={styles.box__inputImgBox}>
                    <div className={styles.box__inputImgContainer}>
                      <p className={styles.box__inputImgHeader}>
                        Dodaj zdjęcia
                        <span style={{ fontWeight: "normal" }}> (max: 3)</span>
                      </p>
                      <label className={styles.box__fileInputLabel}>
                        <input
                          className={styles.box__fileInput}
                          type="file"
                          multiple
                          max={3}
                          onChange={onImageChange}
                          accept="image/png, image/jpg, image/jpeg"
                        />
                        Przeglądaj...
                      </label>
                      <div className={styles.box__filesNames}>
                        {images && (
                          <div className={styles.box__fileList}>
                            {Array.from(images).map((file, index) => (
                              <div key={index}>{file.name}, </div>
                            ))}
                          </div>
                        )}
                      </div>
                      <button
                        className={styles.box__deleteFiles}
                        onClick={onClick}
                      >
                        Usuń dodane pliki
                      </button>
                    </div>

                    <label className={styles.box__inputImgError}>
                      {imgError}
                    </label>
                  </div>
                  {/* <RelativeInfobox /> */}
                </div>
                {/* <p style={{ color: "#DB3041" }}>{duplicateError}</p> */}
              </form>
            </div>
            <div className={styles.box__contentBox}>
              <p className={styles.box__info}>
                Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                Incidunt, enim nam dignissimos praesentium temporibus aliquam?
              </p>
              <p className={styles.box__info}>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Doloremque, maiores!
              </p>
              <div className={styles.box__imgBox}>
                <img
                  className={styles.box__img}
                  src={imgType}
                  alt="zdjęcie zwierzaka"
                />
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    </Page>
  );
}
