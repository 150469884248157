import React from "react";
import styles from "./betterloading.module.scss";

export default function Loading() {
  return (
    <div className={styles.loading}>
      <span className={styles.circle}></span>
    </div>
  );
}
