import axios from "axios";
import Header from "../../components/header/Header";
import Page from "../../components/page/Page";
import Wrapper from "../../components/wrapper/Wrapper";
import styles from "./forgetPassword.module.scss";
import { url } from "../../url";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import InputField from "../../components/forms/InputField/InputField";
import { useUser } from "../../hooks/useUser";
import Infobox from "../../components/Infobox/Infobox";

export default function ForgetPassword() {
  const [email, setEmail] = useState<string>("");
  const [emailError, setEmailError] = useState<string>("");
  const { setDataChangeStatus, setIsError } = useUser();

  const handleEmailChange = (e: any) => {
    setEmail(e.target.value);
  };

  const sendEmail = async (resertEmail: any) => {
    let response;
    try {
      response = await axios.post(url + "/auth/forgetPassword", resertEmail, {
        headers: {
          "Content-Type": "application/json",
        },
      });
    } catch (error: any) {
      response = error.response;
      console.log(response);
    }
    //console.log(response.data);
    return response.data;
  };

  const handleClick = (event: any) => {
    setEmailError("");
    const resertEmail = { email: email };
    if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
      setEmailError("Prosze podać poprawny adres email.");
      event.preventDefault();
      return;
    }
    mutate(resertEmail);
    event.preventDefault();
  };

  const { mutate } = useMutation({
    mutationFn: sendEmail,
    onSuccess: (data: any) => {
      console.log(data);
      if (data.message === "Cant find user") {
        setEmailError("Nie ma takiego adresu email");
        return;
      } else if (data.message === "Email sent") {
        setDataChangeStatus("E-mail został wysłany");
        setEmail("");
        setIsError(false);
        return;
      }
    },
  });

  return (
    <Page>
      <Wrapper>
        <div className={styles.forgetPassword}>
          <Header>Link do zmiany hasła</Header>
          <div className={styles.forgetPassword__container}>
            <Infobox></Infobox>
            <p className={styles.forgetPassword__description}>
              Wpisz adres e-mail na, kórty zostanie wysłany link do zresetowania
              hasła
            </p>
            <InputField
              label={"E-mail"}
              name={"email"}
              value={email}
              onChange={handleEmailChange}
              error={emailError}
              type={"text"}
              autoComplete="false"
              required
            ></InputField>
            <button
              className={styles.forgetPassword__saveChangesButton}
              onClick={handleClick}
            >
              Zresetuj hasło
            </button>
          </div>
        </div>
      </Wrapper>
    </Page>
  );
}
