import { ReactNode, useEffect, useRef } from "react";
import styles from "./dialog.module.scss";

type typeDialog = {
  children: ReactNode;
  dependency: any;
  handleOnClose?: Function;
};
export default function Dialog({
  children,
  dependency,
  handleOnClose,
}: typeDialog) {
  const dialogRef = useRef<HTMLDialogElement | null>(null);
  
  const closeDialog = () => {
    if (!dialogRef.current) {
      return;
    }
    if (handleOnClose) handleOnClose();
    dialogRef.current?.close();
  };

  useEffect(() => {
    if (!dialogRef.current) {
      return;
    }
    dialogRef.current.showModal();

    dialogRef.current.addEventListener("close", closeDialog);
    return () => {
      dialogRef.current?.removeEventListener("close", closeDialog);
    };
  }, [dependency]);

  return (
    <dialog className={styles.dialog} ref={dialogRef}>
      {children}
    </dialog>
  );
}
