import React from "react";
import { useAuth } from "../../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import styles from "./loginComponent.module.scss";
import { LuLogIn } from "react-icons/lu";

export default function LoginComponent() {
  const navigate = useNavigate();

  const handleLogin = () => {
    navigate("/login");
  };

  return (
    <div onClick={handleLogin} className={styles.login}>
      <LuLogIn className={styles.login__linkIcon} />
      <p>Zaloguj się</p>
    </div>
  );
}
