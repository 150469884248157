import { useEffect, useRef, useState } from "react";
import styles from "./changeEmail.module.scss";
import InputField from "../InputField/InputField";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { url } from "../../../url";
import { useUser } from "../../../hooks/useUser";
import { setegid } from "process";

interface ChangeEmailProps {
  isOpen: boolean;
  onClose: () => void;
  label: string;
}

export default function ChangeEmail({
  isOpen,
  onClose,
  label,
}: ChangeEmailProps) {
  const popupRef = useRef<HTMLDivElement>(null);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [emailError, setEmailError] = useState<string>("");
  const [passwordError, setPasswordError] = useState<string>("");
  const [emailChangeError, setEmailChangeError] = useState<String>("");
  const { updateUserData, userData, setDataChangeStatus, setIsError } =
    useUser();
  const oldEmail = userData.email;

  const handleEmailChange = (e: any) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e: any) => {
    setPassword(e.target.value);
  };

  const loginUser = async (user: any) => {
    let response;
    try {
      response = await axios.post(`${url}/auth/login`, user, {
        headers: {
          "Content-Type": "application/json",
        },
      });
    } catch (error: any) {
      response = error.response;
      console.log(response);
    }
    console.log(response.data);
    return response.data;
  };

  const changeDataUser = async (user: any) => {
    //update user data
    let response;
    try {
      response = await axios.patch(url + "/user", user, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      });
    } catch (error: any) {
      response = error.response;
      console.log(response);
    }
    console.log(response.data);
    return response.data;
  };

  const handleSubmit = (event: any) => {
    //#1 check if the password is correct and new email is valid
    setEmailError("");
    setPasswordError("");
    const user = { email: oldEmail, password };
    if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
      setEmailError("Prosze podać poprawny adres email.");
      event.preventDefault();
      return;
    }
    mutate(user);
    event.preventDefault();
  };

  const { mutate: mutateEmail } = useMutation({
    //#3 change email
    mutationFn: changeDataUser,
    onSuccess: (data: any) => {
      if (data.message === "Cant find user") {
        setDataChangeStatus("Nie ma tekiego użytkownika");
        setIsError(true);
        return;
      } else if (data.message === "User credentials updated successfully") {
        setDataChangeStatus("E-mial zmieniony pomyślnie");
        setIsError(false);
        updateUserData(data); //#4 change context
        setPassword("");
        setEmail("");
        onClose();
        return;
      }
    },
  });

  const { mutate } = useMutation({
    //#2 log in
    mutationFn: loginUser,
    onSuccess: (data: any) => {
      console.log(data);
      if (data.message === "Cant find user") {
        setEmailError("Nie ma takiego adresu email");
        return;
      } else if (data.message === "Wrong Password!") {
        setPasswordError("Niepoprawne hasło!");
        setDataChangeStatus("Niepoprawne hasło!");
        console.log("Niepoprawne hasło!");
        setIsError(true);
        return;
      } else if (data.message === "Succesfully authorized") {
        const user = { email: email };
        mutateEmail(user);
        return;
      }
    },
  });

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target as Node)
      ) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, onClose]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setEmailChangeError("");
    }, 3000);
    return () => clearTimeout(timer);
  }, [emailChangeError]);

  if (!isOpen) return null;

  return (
    <form onSubmit={handleSubmit}>
      <div className={styles.changeName} ref={popupRef}>
        <p style={{ color: "green" }}>{emailChangeError}</p>
        <div className={styles.changeName__mainContainer}>
          <div className={styles.changeName__topContainer}>
            <p>{label}</p>
            <span className={styles.changeName__close} onClick={onClose}>
              &times;
            </span>
          </div>
          <div className={styles.changeName__inputContainer}>
            <InputField
              label={"Nowy E-mail"}
              name={"email"}
              value={email}
              onChange={handleEmailChange}
              error={emailError}
              type={"text"}
              autoComplete="new-password"
              required
            ></InputField>
            <InputField
              label={"Hasło"}
              name={"password"}
              value={password}
              onChange={handlePasswordChange}
              error={passwordError}
              type={"password"}
              autoComplete="new-password"
              required
            ></InputField>
          </div>
          <button
            className={styles.changeName__saveChangesButton}
            type="submit"
          >
            Zapisz zmiany
          </button>
        </div>
      </div>
    </form>
  );
}
