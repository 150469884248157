import { useEffect, useRef, useState } from "react";
import styles from "./changePassword.module.scss";
import InputField from "../InputField/InputField";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { url } from "../../../url";
import { useUser } from "../../../hooks/useUser";

interface ChangeEmailProps {
  isOpen: boolean;
  onClose: () => void;
  label: string;
}

export default function ChangeEmail({
  isOpen,
  onClose,
  label,
}: ChangeEmailProps) {
  const popupRef = useRef<HTMLDivElement>(null);
  const [oldPassword, setOldPassword] = useState<string>("");
  const [passwordError, setPasswordError] = useState<string>("");
  const token = localStorage.getItem("token");
  const { userData, updateUserData, setDataChangeStatus, setIsError } =
    useUser();
  const email = userData.email;
  const [formData, setFormData] = useState({
    password: "",
    repeatPassword: "",
  });

  const handleOldPassword = (e: any) => {
    setOldPassword(e.target.value);
  };

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const loginUser = async (user: any) => {
    let response;
    try {
      response = await axios.post(`${url}/auth/login`, user, {
        headers: {
          "Content-Type": "application/json",
        },
      });
    } catch (error: any) {
      response = error.response;
      console.log(response);
    }
    return response.data;
  };

  const changeDataUser = async (user: any) => {
    let response;
    try {
      response = await axios.patch(url + "/user", user, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      });
    } catch (error: any) {
      response = error.response;
      console.log(response);
    }
    return response.data;
  };

  const handleSubmit = (event: any) => {
    //#1 check if the password is correct and new email is valid
    setPasswordError("");
    const user = { email: email, password: oldPassword };
    if (formData.password.length < 8) {
      setPasswordError("Hasło musi mieć minimum 8 znaków");
      event.preventDefault();
      return;
    }

    if (formData.password !== formData.repeatPassword) {
      setPasswordError("Hasła nie są takie same");
      event.preventDefault();
      return;
    }
    mutate(user);
    event.preventDefault();
  };

  const { mutate: mutatePassowrd } = useMutation({
    //#3 change password
    mutationFn: changeDataUser,
    onSuccess: (data: any) => {
      console.log(data);
      if (data.message === "Cant find user") {
        setDataChangeStatus("Nie ma tekiego użytkownika");
        setIsError(true);
        return;
      } else if (data.message === "User credentials updated successfully") {
        setDataChangeStatus("Hasło zosatło zmienione pomyślnie");
        setIsError(false);
        updateUserData(data); //#4 change context
        setOldPassword("");
        onClose();
        setFormData({
          password: "",
          repeatPassword: "",
        });
        return;
      }
    },
  });

  const { mutate } = useMutation({
    //#2 log in
    mutationFn: loginUser,
    onSuccess: (data: any) => {
      console.log(data);
      if (data.message === "Wrong Password!") {
        setPasswordError("Niepoprawne hasło!");
        setDataChangeStatus("Niepoprawne hasło!");
        setIsError(true);
        setOldPassword("");
        setFormData({
          password: "",
          repeatPassword: "",
        });
        return;
      } else if (data.message === "Succesfully authorized") {
        const user = { password: formData.password };
        mutatePassowrd(user);
        return;
      }
    },
  });

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target as Node)
      ) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <form onSubmit={handleSubmit}>
      <div className={styles.changeName} ref={popupRef}>
        <div className={styles.changeName__mainContainer}>
          <div className={styles.changeName__topContainer}>
            <p>{label}</p>
            <span className={styles.changeName__close} onClick={onClose}>
              &times;
            </span>
          </div>
          <div className={styles.changeName__inputContainer}>
            <InputField
              label={"Obecne hasło"}
              name={"oldPassword"}
              value={oldPassword}
              onChange={handleOldPassword}
              error={""}
              type={"password"}
              autoComplete="new-password"
              required
            ></InputField>
            <InputField
              label={"Nowe hasło"}
              name={"password"}
              value={formData.password}
              onChange={handleChange}
              error={passwordError}
              autoComplete="new-password"
              type={"password"}
              required
            ></InputField>
            <InputField
              label={"Powtórz nowe hasło"}
              name={"repeatPassword"}
              value={formData.repeatPassword}
              onChange={handleChange}
              error={passwordError}
              autoComplete="new-password"
              type={"password"}
              required
            ></InputField>
          </div>
          <button
            className={styles.changeName__saveChangesButton}
            type="submit"
          >
            Zapisz zmiany
          </button>
        </div>
      </div>
    </form>
  );
}
