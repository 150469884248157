import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from "@tanstack/react-query";
import axios from "axios";
import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useMemo,
  useState,
} from "react";
import { url } from "../url";

interface AccountType {
  type: string;
  isOwner: boolean;
}

interface User {
  _id: string;
  name: string;
  surname: string;
  username: string;
  email: string;
  photo?: string;
  catIDs: string[];
  dogIDs: string[];
  accountType: AccountType;
}

export interface UserContextType {
  user?: User;
  userData: User;
  getUser: () => void;
  getUserData: () => void;
  // getUserName: () => string;
  // getUserSurname: () => string;
  // getUserUsername: () => string;
  // getUserEmail: () => string;
  // getUserCats: () => string[];
  // getUserDogs: () => string[];
  // getAccType: () => AccountType;
  updateUserData: (response: { user: User }) => void;
  error?: Error | null;
  isLoading: boolean;
  dataChangeStatus: String;
  setDataChangeStatus: Dispatch<SetStateAction<string>>;
  isError: boolean;
  setIsError: Dispatch<SetStateAction<boolean>>;
}

// Create a QueryClient instance
const queryClient = new QueryClient();

// Create the context
export const UserContext = createContext<UserContextType | undefined>(
  undefined,
);

// Provider component
export const UserProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [dataChangeStatus, setDataChangeStatus] = useState<string>("");
  const [isError, setIsError] = useState<boolean>(true); //is needef for color in infobox

  const [userData, setUserData] = useState<User>({
    _id: "",
    name: "",
    surname: "",
    username: "",
    email: "",
    photo: "",
    catIDs: [],
    dogIDs: [],
    accountType: {
      type: "",
      isOwner: false,
    },
  });

  const fetchUser = async (): Promise<User> => {
    let response;
    if (localStorage.getItem("token") === "true") {
      try {
        const response = await axios.get(`${url}/user/credentials`, {
          withCredentials: true,
        });
        return response.data.user;
      } catch (error: any) {
        response = error.response;
        console.log(response);
      }
    }
    return {
      _id: "",
      name: "",
      surname: "",
      username: "",
      email: "",
      photo: "",
      catIDs: [],
      dogIDs: [],
      accountType: {
        type: "",
        isOwner: false,
      },
    };
  };

  const {
    data: user,
    error,
    isLoading,
    refetch,
  } = useQuery<User, Error>({
    queryKey: ["user"],
    queryFn: fetchUser,
  });

  const getUser = async () => {
    await refetch();
  };

  const updateUserData = (data: { user: User }) => {
    const { user } = data;
    console.log(user);
    console.log("udało się");
    setUserData({
      _id: user._id || "",
      name: user.name || "",
      surname: user.surname || "",
      username: user.username || "",
      email: user.email || "",
      photo: user.photo || "",
      catIDs: user.catIDs || [],
      dogIDs: user.dogIDs || [],
      accountType: {
        type: user.accountType.type || "",
        isOwner: user.accountType.isOwner || false,
      },
    });
  };

  const getUserData = useMemo(() => {
    setUserData({
      _id: user ? user._id : "",
      name: user ? user.name : "",
      surname: user ? user.surname : "",
      username: user ? user.username : "",
      email: user ? user.email : "",
      photo: user ? user.photo : "",
      catIDs: user ? user.catIDs : [],
      dogIDs: user ? user.dogIDs : [],
      accountType: {
        type: user ? user.accountType.type : "",
        isOwner: user ? user.accountType.isOwner : false,
      },
    });
    return () => user;
  }, [user]);

  // const getUserName = useMemo(() => {
  // 	// console.log("1");
  // 	return () => (user ? user.name : "");
  // }, [user]);

  // const getUserSurname = useMemo(() => {
  // 	// console.log("2");
  // 	return () => (user ? user.surname : "");
  // }, [user]);

  // const getUserUsername = useMemo(() => {
  // 	// console.log("3");
  // 	return () => (user ? user.username : "");
  // }, [user]);

  // const getUserEmail = useMemo(() => {
  // 	return () => (user ? user.email : "");
  // }, [user]);

  // const getUserCats = useMemo(() => {
  // 	return () => (user ? user.catIDs : []);
  // }, [user]);

  // const getUserDogs = useMemo(() => {
  // 	return () => (user ? user.dogIDs : []);
  // }, [user]);

  // const getAccType = useMemo(() => {
  // 	return () => (user ? user.accountType : { type: "", isOwner: false });
  // }, [user]);

  return (
    <UserContext.Provider
      value={{
        userData,
        getUser,
        getUserData,
        // getUserName,
        // getUserSurname,
        // getUserUsername,
        // getUserEmail,
        // getUserCats,
        // getUserDogs,
        // getAccType,
        updateUserData,
        error,
        isLoading,
        dataChangeStatus,
        setDataChangeStatus,
        isError,
        setIsError,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const UserProviders: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  return (
    <QueryClientProvider client={queryClient}>
      <UserProvider>{children}</UserProvider>
    </QueryClientProvider>
  );
};
