import { useEffect, useRef, useState } from "react";
import styles from "./changeNameNick.module.scss";
import InputField from "../InputField/InputField";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { url } from "../../../url";
import { useUser } from "../../../hooks/useUser";

interface ChangeNameProps {
  isOpen: boolean;
  onClose: () => void;
  label: string;
}

export default function ChangeName({
  isOpen,
  onClose,
  label,
}: ChangeNameProps) {
  const {
    userData,
    updateUserData,
    setDataChangeStatus,
    dataChangeStatus,
    setIsError,
  } = useUser();
  const popupRef = useRef<HTMLDivElement>(null);
  const [nameError, setNameError] = useState<string>("");
  const token = localStorage.getItem("token");
  const [formData, setFormData] = useState({
    name: userData.name,
    surname: userData.surname,
    username: userData.username,
  });

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const changeDataUser = async (user: any) => {
    let response;
    try {
      response = await axios.patch(url + "/user", user, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      });
    } catch (error: any) {
      response = error.response;
      console.log(response);
    }
    return response.data;
  };

  const handleSubmit = (event: any) => {
    setNameError("");
    mutate(formData);
    event.preventDefault();
    onClose();
  };

  const { mutate } = useMutation({
    mutationFn: changeDataUser,
    onSuccess: async (data: any) => {
      console.log(data);
      if (data.message === "Username already taken") {
        setNameError("Ta nazwa użytkownika jest zajęta!");
        setIsError(true);
        return;
      } else if (data.message === "User credentials updated successfully") {
        setDataChangeStatus("Dane zamienione pomyślnie!");
        setIsError(false);
        updateUserData(data); //#4 change context
        return;
      }
    },
  });

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target as Node)
      ) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <form onSubmit={handleSubmit}>
      <div className={styles.changeName} ref={popupRef}>
        <div className={styles.changeName__mainContainer}>
          <div className={styles.changeName__topContainer}>
            <p>{label}</p>
            <span className={styles.changeName__close} onClick={onClose}>
              &times;
            </span>
          </div>
          <div className={styles.changeName__inputContainer}>
            {label === "Edytuj imię i nazwisko" ? (
              <>
                <InputField
                  label={"Imię"}
                  name={"name"}
                  value={formData.name}
                  onChange={handleChange}
                  error={""}
                  type={"text"}
                  required
                ></InputField>
                <InputField
                  label={"Nazwisko"}
                  name={"surname"}
                  value={formData.surname}
                  onChange={handleChange}
                  error={""}
                  type={"text"}
                  required
                ></InputField>
              </>
            ) : (
              <InputField
                label={"Nazwa użytkownika"}
                name={"username"}
                value={formData.username}
                onChange={handleChange}
                error={nameError}
                type={"text"}
                required
              ></InputField>
            )}
          </div>
          <button
            className={styles.changeName__saveChangesButton}
            type="submit"
          >
            Zapisz zmiany
          </button>
        </div>
      </div>
    </form>
  );
}
