import ImageSlider from "components/ImageSlider/ImageSlider";
import { Link, useLocation } from "react-router-dom";
import styles from "./card.module.scss";
import cat from "./img/cat.jpg";
import dog from "./img/dog.jpg";
import kennel from "./img/kennel.jpg";
import petSer from "./img/petSer.jpg";
import seller from "./img/seller.jpg";
interface KennelInterface {
  apartment: string;
  city: string;
  country: string;
  description: string;
  email: string;
  name: string;
  phone: number;
  state: string;
  street: string;
  userAccount: string;
  zipCode: string;
  __v: number;
  _id: string;
}
interface PetServInterface {
  apartment: string;
  city: string;
  country: string;
  description: string;
  email: string;
  phone: number;
  state: string;
  street: string;
  title: string;
  type: string;
  userAccount: string;
  zipCode: string;
  __v: number;
  _id: string;
}
interface SellerInterface {
  apartment: string;
  city: string;
  country: string;
  description: string;
  email: string;
  link: string;
  name: string;
  phone: number;
  state: string;
  street: string;
  type: string;
  userAccount: string;
  zipCode: string;
  __v: number;
  _id: string;
}

interface CardInterface {
  key: string;
  data: any | KennelInterface | PetServInterface | SellerInterface;
  type: string;
}
export default function Card({ data, type }: CardInterface) {
  const location = useLocation();
  return (
    <>
      {(type === "dogs" || type === "cats") && (
        <div className={styles.card} style={{ cursor: "default" }}>
          <div className={styles.card__titleBox}>
            <h3 className={styles.card__title}>{data.name}</h3>
          </div>
          <div className={styles.card__contentBox}>
            <div className={styles.card__petInfoBox}>
              <div className={styles.card__petImgBox}>
                <div className={styles.sliderBox}>
                  <ImageSlider
                    imageUrls={data.imageUrls || []}
                    imageAlt={type === "dogs" ? "zdjęcie psa" : "zdjęcie kota"}
                    pet={type === "dogs" ? "dog" : "cat"}
                  />
                </div>
              </div>
              <div className={styles.card__petMoreInfoBox}>
                <div className={styles.card__singleInfoPetsBox}>
                  <h4 className={styles.card__infoTitle}>Nazwa właściciela</h4>
                  <p className={styles.card__info}>{data.ownerUsername}</p>
                  <h4 className={styles.card__infoTitle}>
                    Adres email właściciela
                  </h4>
                  <p className={styles.card__info}>{data.ownerMail}</p>
                </div>
                <div className={styles.card__singleInfoPetsBox}>
                  <h4 className={styles.card__infoTitle}>Weterynarz</h4>
                  <p className={styles.card__info}>{data.vet}</p>
                </div>
              </div>
            </div>
            <div className={styles.card__infoBox}>
              <div className={styles.card__singleInfoBox}>
                <h4 className={styles.card__infoTitle}>Data urodzenia</h4>
                <p className={styles.card__info}>
                  {data.dateOfBirth?.split("T")[0]}
                </p>
              </div>
              <div className={styles.card__singleInfoBox}>
                <h4 className={styles.card__infoTitle}>Chip</h4>
                <p className={styles.card__info}>{data.chipNumber}</p>
              </div>
            </div>
            <div className={styles.card__additionalInfoBox}>
              <img
                className={styles.card__additionalInfoImg}
                src={type === "dogs" ? dog : cat}
                alt={type === "dogs" ? "obrazek psa" : "obrazek kota"}
              />
              <p className={styles.card__additionalInfo}>{`${data.origin}`}</p>
            </div>
          </div>
        </div>
      )}
      {(type === "kennels" || type === "pet_services") && (
        <div className={styles.card}>
          <Link
            className={styles.card__link}
            to={
              type === "kennels"
                ? `/kennels/${data._id}`
                : `/pet_services/${data._id}`
            }
            state={{ previousLocation: location }}
          ></Link>
          <div className={styles.card__titleBox}>
            <h3 className={styles.card__title}>
              {type === "kennels" ? data.name : data.title}
            </h3>
          </div>
          <div className={styles.imgBox}>
            <div style={{ position: "relative" }}>
              <div className={styles.sliderBox}>
                <ImageSlider
                  imageUrls={data.imageUrls || []}
                  imageAlt={
                    type === "kennels" ? "zdjęcie hodowli" : "zdjęcie usług"
                  }
                  businessType={
                    type === "kennels" ? "kennel" : "serviceProvider"
                  }
                />
              </div>
              <div className={styles.ratings}>
                <p className={styles.average}>
                  {data.rating.average
                    ? data.rating.average?.toFixed(1).replace(".", ",")
                    : "0,0"}
                </p>
                <p className={styles.total}>
                  {data.rating.total}{" "}
                  {data.rating.total === 1
                    ? "opinia"
                    : data.rating.total < 5 && !(data.rating.total === 0)
                      ? "opinie"
                      : "opinii"}
                </p>
              </div>
            </div>
          </div>
          <div className={styles.card__contentBox}>
            <div className={styles.card__infoBox}>
              <div className={styles.card__singleFullInfoBox}>
                <h4 className={styles.card__infoTitle}>
                  {type === "kennels" ? "Typ hodowli" : "Typ usługi"}
                </h4>
                <p className={styles.card__infoFull}>{data.type}</p>
              </div>
            </div>
            <div className={styles.card__infoBox}>
              <div className={styles.card__singleInfoBox}>
                <h4 className={styles.card__infoTitle}>Adres email</h4>
                <p className={styles.card__info}>{data.email}</p>
              </div>
              <div className={styles.card__singleInfoBox}>
                <h4 className={styles.card__infoTitle}>Numer telefonu</h4>
                <p className={styles.card__info}>{data.phone}</p>
              </div>
            </div>
            <div className={styles.card__descriptionBox}>
              <h4 className={styles.card__infoTitle}>Opis</h4>
              <p className={styles.card__infoFull}>{data.description}</p>
            </div>
            <div className={styles.card__additionalInfoBox}>
              <img
                className={styles.card__additionalInfoImg}
                src={type === "kennels" ? kennel : petSer}
                alt={type === "kennels" ? "obrazek hodowli" : "obrazek usługi"}
              />
              <p
                className={styles.card__additionalInfo}
              >{`${data.city}, ${data.country}`}</p>
            </div>
          </div>
        </div>
      )}
      {type === "sellers" && (
        <div className={styles.card} style={{ cursor: "default" }}>
          <div className={styles.card__titleBox}>
            <h3 className={styles.card__title}>{data.name}</h3>
          </div>
          <div className={styles.card__contentBox}>
            <div className={styles.card__infoBox}>
              <div className={styles.card__singleFullInfoBox}>
                <h4 className={styles.card__infoTitle}>Typ sklepu</h4>
                <p className={styles.card__infoFull}>{data.type}</p>
              </div>
            </div>
            <div className={styles.card__infoBox}>
              <div className={styles.card__singleInfoBox}>
                <h4 className={styles.card__infoTitle}>Adres email</h4>
                <p className={styles.card__info}>{data.email}</p>
              </div>
              <div className={styles.card__singleInfoBox}>
                <h4 className={styles.card__infoTitle}>Numer telefonu</h4>
                <p className={styles.card__info}>{data.phone}</p>
              </div>
            </div>
            <div className={styles.card__descriptionBox}>
              <h4 className={styles.card__infoTitle}>Opis</h4>
              <p className={styles.card__infoFull}>{data.description}</p>
            </div>
            <div className={styles.card__additionalInfoBox}>
              <img
                className={styles.card__additionalInfoImg}
                src={seller}
                alt="obrazek sklepu"
              />
              <p
                className={styles.card__additionalInfo}
              >{`${data.city}, ${data.country}`}</p>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
