import { ReactNode } from "react";
import Sidebar from "../navigation/Sidebar/Sidebar";
import styles from "./page.module.scss";
import Infobox from "components/Infobox/Infobox";

export default function Page({ children }: { children: ReactNode }) {
  return (
    <div className={styles.page}>
      <Sidebar />
      
      {children}
    </div>
  );
}
