import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as GaoIcon } from "../../assets/images/gao.svg";
import InputField from "../../components/forms/InputField/InputField";
import { useAuth } from "../../context/AuthContext";
import { useUser } from "../../hooks/useUser";
import { url } from "../../url";
import styles from "./login.module.scss";
import Loading from "components/loading/BetterLoading";

export const loginUser = async (user: any) => {
  let response;
  try {
    response = await axios.post(url + "/auth/login", user, {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    });
  } catch (error: any) {
    response = error.response;
    console.log(response);
  }
  return response.data;
};

export default function Login() {
  const { login } = useAuth();
  const { updateUserData, getUserData } = useUser();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [emailError, setEmailError] = useState<string>("");
  const [passwordError, setPasswordError] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleEmailChange = (e: any) => {
    setEmail(e.target.value);
  };
  const handlePasswordChange = (e: any) => {
    setPassword(e.target.value);
  };

  const { mutate } = useMutation({
    mutationFn: loginUser,
    onSuccess: (data: any) => {
      console.log(data);
      if (data.message === "Cant find user") {
        setEmailError("Nie ma takiego adresu email");
        return;
      } else if (data.message === "Wrong Password!") {
        setPasswordError("Niepoprawne hasło!");
        return;
      } else {
        login(data.token);
        updateUserData(data);
        navigate("/gaoanimalcenter");
        return;
      }
    },
  });

  const handleLoginClick = (event: any) => {
    setEmailError("");
    setPasswordError("");
    const user = { email, password };
    if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
      setEmailError("Prosze podać poprawny adres email.");
      event.preventDefault();
      return;
    }
    setIsLoading(true);
    mutate(user, {
      onSettled: () => {
        setIsLoading(false);
      },
    });
    event.preventDefault();
  };

  useEffect(() => {
    if (localStorage.getItem("token") === "true") {
      getUserData();
      navigate("/gaoanimalcenter");
    }
  }, [navigate]);

  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      event.preventDefault();
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.box}>
        <div className={styles.box__heroImg}></div>
        <div className={styles.box__formBox}>
          <GaoIcon width={124} height={124} style={{ width: "100%" }} />
          <form onSubmit={handleLoginClick}>
            <h2 className={styles.box__brandName}>Gao Animal Center</h2>
            <p className={styles.box__formName}>Logowanie</p>
            <InputField
              label={"E-mail"}
              name={"email"}
              value={email}
              onChange={handleEmailChange}
              error={emailError}
              type={"text"}
              required
              length={254}
            ></InputField>
            <InputField
              label={"Hasło"}
              name={"password"}
              value={password}
              onChange={handlePasswordChange}
              error={passwordError}
              type={"password"}
              required
              length={128}
            ></InputField>

            <div className={styles.box__formBtnBox}>
              <button
                type="submit"
                className={styles.box__formBtn}
                disabled={isLoading}
              >
                {isLoading ? (
                  <Loading></Loading>
                ) : (
                  <>
                    <span>Z</span>
                    <span>a</span>
                    <span>l</span>
                    <span>o</span>
                    <span>g</span>
                    <span>u</span>
                    <span>j</span> <span>s</span>
                    <span>i</span>
                    <span>ę</span>
                  </>
                )}
              </button>
            </div>
            <div className={styles.box__formNoAccountBox}>
              <p className={styles.box__formNoAccount}>
                Nie masz jeszcze konta?{"  "}
                <Link className={styles.box__formNoAccountLink} to="/register">
                  Zarejestruj się
                </Link>
              </p>
              <Link className={styles.box__resetPassword} to="/forget_password">
                Zresetuj hasło
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
