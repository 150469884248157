import React, { useState } from "react";
import ChangePassword from "./ChangePassword";
import styles from "./changePassword.module.scss";

interface ChangePasswordButtonProps {
  label: string;
}

export default function ChangePasswordButton({
  label,
}: ChangePasswordButtonProps) {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  return (
    <div>
      <button onClick={togglePopup} className={styles.popupButton}>
        Edytuj
      </button>
      <ChangePassword
        isOpen={isPopupOpen}
        onClose={closePopup}
        label={`Edytuj ${label}`}
      />
    </div>
  );
}
