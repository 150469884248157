import { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";

interface ProtectedRouteProps {
  element: JSX.Element;
  isPrivate: boolean;
}

export default function ProtectedRoute({
  element,
  isPrivate,
}: ProtectedRouteProps) {
  const { isLoggedIn, checkIsLoggedIn } = useAuth();

  useEffect(() => {
    checkIsLoggedIn();
  });

  if (isPrivate && !isLoggedIn) {
    return <Navigate to="/notlogged" replace />;
  }

  return element;
}
