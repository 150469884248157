import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  breedFilters as breederFilterData,
  sellerFilters as sellerFilterData,
  serviceFilters as servProvFilterData,
  statesFilters as stateFilterData,
} from "utils/enums";
import Dropdown from "../../components/Dropdown/Dropdown";
import Header from "../../components/header/Header";
import BetterLoading from "../../components/loading/BetterLoading";
import Page from "../../components/page/Page";
import Wrapper from "../../components/wrapper/Wrapper";
import { url } from "../../url";
import Card from "./Card";
import styles from "./kennelspetservssellers.module.scss";
interface KennelInterface {
  apartment: string;
  city: string;
  country: string;
  description: string;
  email: string;
  name: string;
  phone: number;
  state: string;
  street: string;
  userAccount: string;
  zipCode: string;
  __v: number;
  _id: string;
}
interface PetServInterface {
  apartment: string;
  city: string;
  country: string;
  description: string;
  email: string;
  phone: number;
  state: string;
  street: string;
  title: string;
  type: string;
  userAccount: string;
  zipCode: string;
  __v: number;
  _id: string;
}
interface SellerInterface {
  apartment: string;
  city: string;
  country: string;
  description: string;
  email: string;
  link: string;
  name: string;
  phone: number;
  state: string;
  street: string;
  type: string;
  userAccount: string;
  zipCode: string;
  __v: number;
  _id: string;
}

interface filterInterface {
  state: string;
  type: string;
}

const collectData = async (call: string, token: string | null) => {
  let response;
  try {
    response = await axios.get(url + call, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (error: any) {
    response = error.response;
  }
  if (call === "/breeder") return response.data.breeders;
  else if (call === "/serviceProvider") return response.data.serviceProviders;
  else if (call === "/seller") return response.data.sellers;
  else if (call === "/dog") return response.data.dogIDs;
  else return response.data.catIDs;
};

export default function KennelsPetServsSellers() {
  const [cardsData, setCardsData] = useState<any>([]);
  const [displayType, setDisplayType] = useState<string>("");
  const [filterBreederData, setFilterBreederData] = useState<any>([]);
  const [filterServData, setFilterServData] = useState<any>([]);
  const [filterSellerData, setFilterSellerData] = useState<any>([]);
  const [typeFilterBreeder, setTypeFilterBreeder] = useState<string>(
    "Filtruj według typu",
  );
  const [typeFilterServ, setTypeFilterServ] = useState<string>(
    "Filtruj według typu",
  );
  const [typeFilterSeller, setTypeFilterSeller] = useState<string>(
    "Filtruj według typu",
  );
  const [stateFilterBreeder, setStateFilterBreeder] = useState<string>(
    "Filtruj według województwa",
  );
  const [stateFilterServ, setStateFilterServ] = useState<string>(
    "Filtruj według województwa",
  );
  const [stateFilterSeller, setStateFilterSeller] = useState<string>(
    "Filtruj według województwa",
  );
  const [filterBreeder, setFilterBreeder] = useState<filterInterface>({
    state: "Brak filtrów",
    type: "Brak filtrów",
  });
  const [filterServ, setFilterServ] = useState<filterInterface>({
    state: "Brak filtrów",
    type: "Brak filtrów",
  });
  const [filterSeller, setFilterSeller] = useState<filterInterface>({
    state: "Brak filtrów",
    type: "Brak filtrów",
  });
  const filterType = ["state", "type"];
  const handleFilterBreederChange = (key: string, value: string) => {
    setFilterBreeder((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };
  const handleFilterServChange = (key: string, value: string) => {
    setFilterServ((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };
  const handleFilterSellerChange = (key: string, value: string) => {
    setFilterSeller((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };
  const location = useLocation();
  const pathname = location.pathname.slice(1);
  const headerName: any = {
    dogs: "Psy",
    cats: "Koty",
    kennels: "Hodowle",
    pet_services: "Usługodawcy",
    sellers: "Sprzedawcy",
  };
  const callType: any = {
    dogs: "/dog",
    cats: "/cat",
    kennels: "/breeder",
    pet_services: "/serviceProvider",
    sellers: "/seller",
  };
  const { data, isError, isLoading, isSuccess, isFetching } = useQuery({
    queryKey: [`${displayType}`],
    queryFn: () =>
      collectData(callType[displayType], localStorage.getItem("token")),
  });
  useEffect(() => {
    setDisplayType(pathname);
    handleFilterBreederChange("state", "Brak filtrów");
    handleFilterBreederChange("type", "Brak filtrów");
    setStateFilterBreeder("Filtruj według województwa");
    setTypeFilterBreeder("Filtruj według typu");
    handleFilterServChange("state", "Brak filtrów");
    handleFilterServChange("type", "Brak filtrów");
    setStateFilterServ("Filtruj według województwa");
    setTypeFilterServ("Filtruj według typu");
    handleFilterSellerChange("state", "Brak filtrów");
    handleFilterSellerChange("type", "Brak filtrów");
    setStateFilterSeller("Filtruj według województwa");
    setTypeFilterSeller("Filtruj według typu");
  }, [pathname]);

  useEffect(() => {
    if (data) {
      setCardsData(data);
      if (pathname === "kennels") {
        setFilterBreederData(data);
      } else if (pathname === "pet_services") {
        setFilterServData(data);
      } else if (pathname === "sellers") {
        setFilterSellerData(data);
      }
    }
  }, [data]);

  useEffect(() => {
    let updatedData = cardsData;
    if (
      filterBreeder["state"] === "Brak filtrów" &&
      filterBreeder["type"] === "Brak filtrów"
    )
      setFilterBreederData(updatedData);
    else if (filterBreeder["type"] === "Brak filtrów")
      updatedData = updatedData.filter(
        (item: any) => item.state === stateFilterBreeder,
      );
    else if (filterBreeder["state"] === "Brak filtrów")
      updatedData = updatedData.filter(
        (item: any) => item.type === typeFilterBreeder,
      );
    else
      updatedData = updatedData.filter(
        (item: any) =>
          item.type === typeFilterBreeder && item.state === stateFilterBreeder,
      );

    setFilterBreederData(updatedData);
  }, [typeFilterBreeder, stateFilterBreeder]);

  useEffect(() => {
    let updatedData = cardsData;
    if (
      filterServ["state"] === "Brak filtrów" &&
      filterServ["type"] === "Brak filtrów"
    )
      setFilterServData(updatedData);
    else if (filterServ["type"] === "Brak filtrów")
      updatedData = updatedData.filter(
        (item: any) => item.state === stateFilterServ,
      );
    else if (filterServ["state"] === "Brak filtrów")
      updatedData = updatedData.filter(
        (item: any) => item.type === typeFilterServ,
      );
    else
      updatedData = updatedData.filter(
        (item: any) =>
          item.type === typeFilterServ && item.state === stateFilterServ,
      );

    setFilterServData(updatedData);
  }, [typeFilterServ, stateFilterServ]);

  useEffect(() => {
    let updatedData = cardsData;
    if (
      filterSeller["state"] === "Brak filtrów" &&
      filterSeller["type"] === "Brak filtrów"
    )
      setFilterSellerData(updatedData);
    else if (filterSeller["type"] === "Brak filtrów")
      updatedData = updatedData.filter(
        (item: any) => item.state === stateFilterSeller,
      );
    else if (filterSeller["state"] === "Brak filtrów")
      updatedData = updatedData.filter(
        (item: any) => item.type === typeFilterSeller,
      );
    else
      updatedData = updatedData.filter(
        (item: any) =>
          item.type === typeFilterSeller && item.state === stateFilterSeller,
      );

    setFilterSellerData(updatedData);
  }, [typeFilterSeller, stateFilterSeller]);

  if (isError) {
    return (
      <Page>
        <Wrapper>
          <div>Wystąpił błąd</div>;
        </Wrapper>
      </Page>
    );
  }
  return (
    <Page>
      <Wrapper>
        <div className={styles.box}>
          <Header>{headerName[displayType]}</Header>
          <div className={styles.box__sectionBox}>
            <div className={styles.box__headingBox}>
              <div className={styles.box__filtersBox}>
                {/* {displayType === "dogs" && (
									<Dropdown dropButtonName={typeFilter} dropDownData={testData} setButtonName={setTypeFilter} />
								)}
								{displayType === "cats" && (
									<Dropdown dropButtonName={typeFilter} dropDownData={testData} setButtonName={setTypeFilter} />
								)} */}
                {displayType === "kennels" && (
                  <>
                    <Dropdown
                      dropButtonName={typeFilterBreeder}
                      dropDownData={breederFilterData}
                      setButtonName={setTypeFilterBreeder}
                      filterType={filterType[1]}
                      handleFilterChange={handleFilterBreederChange}
                    />
                    <Dropdown
                      dropButtonName={stateFilterBreeder}
                      dropDownData={stateFilterData}
                      setButtonName={setStateFilterBreeder}
                      filterType={filterType[0]}
                      handleFilterChange={handleFilterBreederChange}
                    />
                  </>
                )}
                {displayType === "pet_services" && (
                  <>
                    <Dropdown
                      dropButtonName={typeFilterServ}
                      dropDownData={servProvFilterData}
                      setButtonName={setTypeFilterServ}
                      filterType={filterType[1]}
                      handleFilterChange={handleFilterServChange}
                    />
                    <Dropdown
                      dropButtonName={stateFilterServ}
                      dropDownData={stateFilterData}
                      setButtonName={setStateFilterServ}
                      filterType={filterType[0]}
                      handleFilterChange={handleFilterServChange}
                    />
                  </>
                )}
                {displayType === "sellers" && (
                  <>
                    <Dropdown
                      dropButtonName={typeFilterSeller}
                      dropDownData={sellerFilterData}
                      setButtonName={setTypeFilterSeller}
                      filterType={filterType[1]}
                      handleFilterChange={handleFilterSellerChange}
                    />
                    <Dropdown
                      dropButtonName={stateFilterSeller}
                      dropDownData={stateFilterData}
                      setButtonName={setStateFilterSeller}
                      filterType={filterType[0]}
                      handleFilterChange={handleFilterSellerChange}
                    />
                  </>
                )}
              </div>
              {/* <input type="text" /> */}
            </div>
            {pathname === "dogs" && (
              <div className={styles.box__cardsBox}>
                {!isLoading ? (
                  cardsData.map(
                    (
                      item:
                        | KennelInterface
                        | PetServInterface
                        | SellerInterface,
                      index: number,
                    ) => <Card key={item._id} data={item} type={displayType} />,
                  )
                ) : (
                  <BetterLoading />
                )}
              </div>
            )}
            {pathname === "cats" && (
              <div className={styles.box__cardsBox}>
                {!isLoading ? (
                  cardsData.map(
                    (
                      item:
                        | KennelInterface
                        | PetServInterface
                        | SellerInterface,
                      index: number,
                    ) => <Card key={item._id} data={item} type={displayType} />,
                  )
                ) : (
                  <BetterLoading />
                )}
              </div>
            )}
            {pathname === "kennels" && (
              <div className={styles.box__cardsBox}>
                {!isLoading ? (
                  filterBreederData.map(
                    (
                      item:
                        | KennelInterface
                        | PetServInterface
                        | SellerInterface,
                      index: number,
                    ) => <Card key={item._id} data={item} type={pathname} />,
                  )
                ) : (
                  <BetterLoading />
                )}
              </div>
            )}
            {pathname === "pet_services" && (
              <div className={styles.box__cardsBox}>
                {!isLoading ? (
                  filterServData.map(
                    (
                      item:
                        | KennelInterface
                        | PetServInterface
                        | SellerInterface,
                      index: number,
                    ) => <Card key={item._id} data={item} type={pathname} />,
                  )
                ) : (
                  <BetterLoading />
                )}
              </div>
            )}
            {pathname === "sellers" && (
              <div className={styles.box__cardsBox}>
                {!isLoading ? (
                  filterSellerData.map(
                    (
                      item:
                        | KennelInterface
                        | PetServInterface
                        | SellerInterface,
                      index: number,
                    ) => <Card key={item._id} data={item} type={pathname} />,
                  )
                ) : (
                  <BetterLoading />
                )}
              </div>
            )}
          </div>
        </div>
      </Wrapper>
    </Page>
  );
}
