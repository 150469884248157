import axios from "axios";
import Dropdown from "components/Dropdown/Dropdown";
import InputField from "components/forms/InputField/InputField";
import { useEffect, useState } from "react";
import { url } from "../../../../url";
import { useUser } from "hooks/useUser";
import styles from "../../../BusinsessRegistration/businessregistration.module.scss";
import RelativeInfobox from "components/RealtiveInfoBox/RelativeInfobox";
import { useMutation, useQuery } from "@tanstack/react-query";
import DeleteBusinessButton from "../../../../components/forms/DeleteBusiness/DeleteBusinessButton";
import Loading from "components/loading/Loading";
import { serviceTypes, statesTypes } from "utils/enums";

interface ServProvFormData {
  title: string;
  type: string;
  description: string;
  email: string;
  phone: number | undefined;
  street: string;
  apartment: string;
  city: string;
  state: string;
  zipCode: string;
  country: string;
}

export default function PetServices() {
  const [emailError, setEmailError] = useState<string>("");
  const [stateError, setStateError] = useState<string>("");
  const [errorServiceType, setErrorServiceType] = useState<string>("");
  const { setDataChangeStatus, setIsError, userData } = useUser();

  const [servProvFormData, setServProvFormData] = useState<ServProvFormData>({
    title: "",
    type: "",
    description: "",
    email: "",
    phone: undefined,
    street: "",
    apartment: "",
    city: "",
    state: "",
    zipCode: "",
    country: "",
  });

  const [serviceType, setServiceType] = useState<string>("Typ usługi");
  const [stateType, setStateType] = useState<string>("Województwo");

  const getBusinessData = async () => {
    let response;
    try {
      response = await axios.get(url + "/user/business", {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      });
    } catch (error: any) {
      response = error.response;
      console.log(response);
    }
    return response.data.business;
  };

  const handleServProvChange = (event: any) => {
    const { name, value } = event.target;
    setServProvFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const handleServProvTypeChange = (key: string, value: string) => {
    setServProvFormData((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleFormSubmit = async (event: any) => {
    setEmailError("");
    setStateError("");
    const token = localStorage.getItem("token");

    if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(servProvFormData.email)) {
      setEmailError("Proszę podać poprawny adres email.");
      event.preventDefault();
      return;
    }
    if (token) {
      mutate({ formData: servProvFormData });
      event.preventDefault();
    }
    event.preventDefault();
  };

  const changeBusiness = async ({
    formData,
  }: {
    formData: ServProvFormData;
  }) => {
    let response;
    try {
      response = await axios.patch(url + "/user/business", formData, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      });
    } catch (error: any) {
      response = error.response;
      console.log(response);
    }
    return response.data;
  };

  const { data, isLoading, error } = useQuery({
    queryKey: ["business"],
    queryFn: () => getBusinessData(),
  });

  const { mutate } = useMutation({
    mutationFn: changeBusiness,
    onSuccess: (data: any) => {
      console.log(data.message);
      if (data.status === 500) {
        if (
          data.message ===
          "ServiceProvider validation failed: type: `` is not a valid enum value for path `type`., state: `` is not a valid enum value for path `state`."
        ) {
          setStateError("Wybierz województwo");
          setErrorServiceType("Wybierz typ usługi");
        }
        if (
          data.message ===
          "ServiceProvider validation failed: type: `` is not a valid enum value for path `type`."
        ) {
          setErrorServiceType("Wybierz typ usługi");
        }
        if (
          data.message ===
          "ServiceProvider validation failed: state: `` is not a valid enum value for path `state`."
        ) {
          setStateError("Wybierz województwo");
        }
      } else {
        setDataChangeStatus(`Udało się zmienić dane!`);
        setIsError(false);
      }
    },
  });

  useEffect(() => {
    if (data) {
      setServProvFormData({
        title: data.title,
        type: data.type,
        description: data.description,
        email: data.email,
        phone: data.phone,
        street: data.street,
        apartment: data.apartment,
        city: data.city,
        state: data.state,
        zipCode: data.zipCode,
        country: data.country,
      });
      setServiceType(data.type);
      setStateType(data.state);
    }
  }, [data]);

  return (
    <>
      {!isLoading ? (
        <>
          <form className={styles.box__form} onSubmit={handleFormSubmit}>
            <div className={styles.box__inputBox}>
              <InputField
                label={"Nazwa usługi"}
                name={"title"}
                value={servProvFormData.title}
                onChange={handleServProvChange}
                error={""}
                type={"text"}
                required
                length={100}
              />
              <Dropdown
                dropButtonName={serviceType}
                dropDownData={serviceTypes}
                setButtonName={setServiceType}
                handleChange={handleServProvTypeChange}
                changeType="type"
                error={errorServiceType}
              />
            </div>
            <div className={styles.box__inputBox}>
              <InputField
                label={"Adres email"}
                name={"email"}
                value={servProvFormData.email}
                onChange={handleServProvChange}
                error={emailError}
                type={"text"}
                required
                length={254}
              />
              <InputField
                label={"Numer telefonu"}
                name={"phone"}
                value={servProvFormData.phone}
                onChange={handleServProvChange}
                error={""}
                type={"number"}
                required
                length={15}
              />
            </div>
            <div className={styles.box__inputBox}>
              <InputField
                label={"Ulica"}
                name={"street"}
                value={servProvFormData.street}
                onChange={handleServProvChange}
                error={""}
                type={"text"}
                required
                length={100}
              />
              <InputField
                label={"Numer budynku"}
                name={"apartment"}
                value={servProvFormData.apartment}
                onChange={handleServProvChange}
                error={""}
                type={"text"}
                required={false}
                length={10}
              />
            </div>
            <div className={styles.box__inputBox}>
              <InputField
                label={"Kod pocztowy"}
                name={"zipCode"}
                value={servProvFormData.zipCode}
                onChange={handleServProvChange}
                error={""}
                type={"text"}
                required
                length={11}
              />
              <InputField
                label={"Miasto"}
                name={"city"}
                value={servProvFormData.city}
                onChange={handleServProvChange}
                error={""}
                type={"text"}
                required
                length={58}
              />
            </div>
            <div className={styles.box__inputBox}>
              <Dropdown
                dropButtonName={stateType}
                dropDownData={statesTypes}
                setButtonName={setStateType}
                handleChange={handleServProvTypeChange}
                changeType="state"
                error={stateError}
              />
              <InputField
                label={"Państwo"}
                name={"country"}
                value={servProvFormData.country}
                onChange={handleServProvChange}
                error={""}
                type={"text"}
                required
                length={56}
              />
            </div>
            <InputField
              label={"Opis usługi"}
              name={"description"}
              value={servProvFormData.description}
              onChange={handleServProvChange}
              error={""}
              type={"text"}
              required
              length={1000}
            />
            {/* <RelativeInfobox /> */}
            <button className={styles.box__changeData} type="submit">
              Zmień dane
            </button>
          </form>
          <DeleteBusinessButton
            label={"Czy na pewno chcesz usunąć działalność?"}
          />
        </>
      ) : (
        <Loading />
      )}
    </>
  );
}
