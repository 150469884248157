import Header from "../../components/header/Header";
import Page from "../../components/page/Page";
import Wrapper from "../../components/wrapper/Wrapper";
import styles from "./home.module.scss";

export default function Home() {
  return (
    <Page>
      <Wrapper>
        <div className={styles.home}>
          <Header>Strona główna</Header>
        </div>
      </Wrapper>
    </Page>
  );
}
