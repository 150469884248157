import React, { useState } from "react";
import ChangePicture from "./ChangePicture";
import styles from "./changePicture.module.scss";
import { LuCamera } from "react-icons/lu";
import { useUser } from "hooks/useUser";

interface ChangePictureButtonProps {
  label: string;
}

export default function ChangeEmailButton({ label }: ChangePictureButtonProps) {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const { userData } = useUser();
  const photo = userData.photo ? userData.photo : "";

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  return (
    <div>
      <button onClick={togglePopup} className={styles.popupButton}>
        {photo !== "" ? (
          <img src={photo} className={styles.popupButton__profileImg}></img>
        ) : (
          <LuCamera className={styles.popupButton__icon} />
        )}
      </button>
      <ChangePicture
        isOpen={isPopupOpen}
        onClose={closePopup}
        label={`Edytuj ${label}`}
      />
    </div>
  );
}
