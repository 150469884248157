import Start from "../pages/Start/Start";
import Login from "../pages/Login/Login";
import Signup from "../pages/Signup/Signup";
import Home from "../pages/Home/Home";
import Profile from "../pages/Profile/Profile";
import Settings from "../pages/Settings/Settings";
import Registration from "../pages/Registration/Registration";
import BusinessRegistration from "../pages/BusinsessRegistration/BusinessRegistration";
import NotLogged from "../pages/NotLogged/NotLogged";
import KennelsPetServsSellers from "../pages/Kennels_PetServs_Sellers/KennelsPetServsSellers";
import SingleBusiness from "../pages/SingleBusiness/SingleBusiness";
import ForgetPassword from "../pages/ForgetPassword/ForgetPassword";
import ResetPassword from "../pages/ResetPassword/ResetPassword";

export const routes = [
  {
    path: "/",
    component: Start,
    isPrivate: false,
  },
  {
    path: "/cats",
    component: KennelsPetServsSellers,
    isPrivate: false,
  },
  {
    path: "/dogs",
    component: KennelsPetServsSellers,
    isPrivate: false,
  },
  {
    path: "/forget_password",
    component: ForgetPassword,
    isPrivate: false,
  },
  {
    path: "/gaoanimalcenter",
    component: Home,
    isPrivate: false,
  },
  {
    path: "/kennels",
    component: KennelsPetServsSellers,
    isPrivate: false,
  },
  {
    path: "/kennels/:id",
    component: SingleBusiness,
    isPrivate: false,
  },
  {
    path: "/login",
    component: Login,
    isPrivate: false,
  },
  {
    path: "/notlogged",
    component: NotLogged,
    isPrivate: false,
  },
  {
    path: "/pet_services",
    component: KennelsPetServsSellers,
    isPrivate: false,
  },
  {
    path: "/pet_services/:id",
    component: SingleBusiness,
    isPrivate: false,
  },
  {
    path: "/profile",
    component: Profile,
    isPrivate: true,
  },
  {
    path: "/register",
    component: Signup,
    isPrivate: false,
  },
  {
    path: "/registration/cat",
    component: Registration,
    isPrivate: true,
  },
  {
    path: "/registration/dog",
    component: Registration,
    isPrivate: true,
  },
  {
    path: "/registration/business",
    component: BusinessRegistration,
    isPrivate: true,
  },
  {
    path: "/reset-password/:token",
    component: ResetPassword,
    isPrivate: false,
  },
  {
    path: "/sellers",
    component: KennelsPetServsSellers,
    isPrivate: false,
  },
  {
    path: "/sellers/:id",
    component: SingleBusiness,
    isPrivate: false,
  },
  {
    path: "/settings",
    component: Settings,
    isPrivate: true,
  },
];
