import { ReactNode } from "react";
import BusinessNav from "../BusinessNav/BusinessNav";
import styles from "./wrapper.module.scss";
export default function Wrapper({ children }: { children: ReactNode }) {
  return (
    <div className={styles.wrapper}>
      <div>
        <BusinessNav />
      </div>
      {children}
    </div>
  );
}
