import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import Dialog from "components/Dialog/Dialog";
import InputField from "components/forms/InputField/InputField";
import { useEffect, useState } from "react";
import { IoClose } from "react-icons/io5";
import { LuBookOpen, LuBug } from "react-icons/lu";
import { url } from "../../url";
import styles from "./feedback.module.scss";
import { useUser } from "hooks/useUser";

const postFeedback = async ({ data }: { data: any }) => {
  let response;
  console.log(data);
  try {
    response = await axios.post(url + "/user/feedback", data, {
      withCredentials: true,
    });
  } catch (error: any) {
    response = error.response;
    console.log(response);
  }
  return response.data;
};

export default function Feedback() {
  const [popFeedback, setPopFeedback] = useState<boolean>(false);
  const [popBug, setPopBug] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<string>("");
  const { setDataChangeStatus, setIsError } = useUser();
  const [data, setData] = useState({
    name: "",
    email: "",
    message: "",
    type: "",
  });

  const handleDataChange = (event: any) => {
    const { name, value } = event.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleDataKeyChange = (key: string, value: string) => {
    setData((prevData) => ({
      ...prevData,
      [key]: value,
    }));
  };

  const handleOnFeedbackClose = () => {
    setPopFeedback(false);
    setData((prevData) => ({
      ...prevData,
      name: "",
      email: "",
      message: "",
      type: "",
    }));
  };

  const handleOnBugClose = () => {
    setPopBug(false);
    setData((prevData) => ({
      ...prevData,
      name: "",
      email: "",
      message: "",
      type: "",
    }));
  };

  const { mutate } = useMutation({
    mutationFn: postFeedback,
    onSuccess: (data: any) => {
      console.log(data.message);
      handleOnFeedbackClose();
      handleOnBugClose();
      setDataChangeStatus("Dziękujemy za wypełnienie formularza!");
      setIsError(false);
    },
  });

  const handleOnSubmit = (event: any) => {
    event.preventDefault();
    if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(data.email)) {
      setEmailError("Prosze podać poprawny adres email.");
      return;
    }
    if (popFeedback) {
      handleDataKeyChange("type", "feedback");
      mutate({ data: data });
    } else {
      handleDataKeyChange("type", "bug");
      mutate({ data: data });
    }
  };

  useEffect(() => {
    if (popFeedback) handleDataKeyChange("type", "feedback");
    if (popBug) handleDataKeyChange("type", "bug");
  }, [popFeedback, popBug]);

  return (
    <>
      {popFeedback && (
        <Dialog dependency={popFeedback} handleOnClose={handleOnFeedbackClose}>
          <div className={styles.dialogBox}>
            <button className={styles.closeBtn} onClick={handleOnFeedbackClose}>
              <IoClose className={styles.closeIcon} />
            </button>
            <p className={styles.dialogTitle}>
              Podziel się z nami swoją opinią! Napisz co Ci się podoba, a co
              moglibyśmy dodać lub zmienić.
            </p>
            <form className={styles.form} onSubmit={handleOnSubmit}>
              <InputField
                label={"Imię"}
                name={"name"}
                value={data.name}
                onChange={handleDataChange}
                error={""}
                type={"text"}
                required
                length={100}
              />
              <InputField
                label={"Adres email"}
                name={"email"}
                value={data.email}
                onChange={handleDataChange}
                error={emailError}
                type={"text"}
                required
                length={100}
              />
              <textarea
                className={styles.textArea}
                name="message"
                value={data.message}
                minLength={5}
                maxLength={1000}
                placeholder="Dodaj opinię..."
                onChange={handleDataChange}
                required
              ></textarea>
              <button className={styles.submitBtn} type="submit">
                Wyślij
              </button>
            </form>
          </div>
        </Dialog>
      )}
      {popBug && (
        <Dialog dependency={popBug} handleOnClose={handleOnBugClose}>
          <div className={styles.dialogBox}>
            <button className={styles.closeBtn} onClick={handleOnBugClose}>
              <IoClose className={styles.closeIcon} />
            </button>
            <p className={styles.dialogTitle}>
              Zauważyłeś błąd? Coś nie działa jak należy? Napisz nam o tym!
            </p>
            <form className={styles.form} onSubmit={handleOnSubmit}>
              <InputField
                label={"Imię"}
                name={"name"}
                value={data.name}
                onChange={handleDataChange}
                error={""}
                type={"text"}
                required
                length={100}
              />
              <InputField
                label={"Adres email"}
                name={"email"}
                value={data.email}
                onChange={handleDataChange}
                error={emailError}
                type={"text"}
                required
                length={100}
              />
              <textarea
                className={styles.textArea}
                name="message"
                value={data.message}
                minLength={5}
                maxLength={1000}
                placeholder="Zgłoś błąd..."
                onChange={handleDataChange}
                required
              ></textarea>
              <button className={styles.submitBtn} type="submit">
                Wyślij
              </button>
            </form>
          </div>
        </Dialog>
      )}
      <div className={styles.btnBox}>
        <button
          className={styles.btnFeedback}
          onClick={() => setPopFeedback(!popFeedback)}
        >
          <LuBookOpen className={styles.btnFeedbackIcon} />
        </button>
        <button className={styles.btnBug} onClick={() => setPopBug(!popBug)}>
          <LuBug className={styles.btnBugIcon} />
        </button>
      </div>
    </>
  );
}
