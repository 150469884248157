import { useEffect, useState } from "react";
import { useUser } from "../../hooks/useUser";
import styles from "./infobox.module.scss";
import { LuX } from "react-icons/lu";

export default function Infobox() {
  const { dataChangeStatus, setDataChangeStatus, isError } = useUser();
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    if (dataChangeStatus !== "") {
      setShowPopup(true);

      const timer = setTimeout(() => {
        setShowPopup(false);
        setDataChangeStatus("");
      }, 3500);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [dataChangeStatus]);

  const onClose = () => {
    const timer = setTimeout(() => {
        setShowPopup(false);
        setDataChangeStatus("");
      }, 0);

      return () => {
        clearTimeout(timer);
      };
  }

  if (!showPopup) return null;

  return (
    <div
      className={`${isError === false ? styles.popup : styles.popup__error}`}
    >
      {dataChangeStatus}
      <button className={`${isError === false ? styles.popup__close : styles.popup__error__close}`} onClick={onClose}> 
        <LuX />                 
      </button>
    </div>
  );
}
