import Page from "../../components/page/Page";
import Wrapper from "../../components/wrapper/Wrapper";
import styles from "./settings.module.scss";
import { UserProviders, UserProvider } from "../../context/UserContext";
import { useUser } from "../../hooks/useUser";
import Header from "../../components/header/Header";
import Loading from "../../components/loading/Loading";
import { useEffect, useState } from "react";
import ChangeNameNickButton from "../../components/forms/ChangeNameNick/ChangeNameNickButton";
import ChangeEmailButton from "../../components/forms/ChangeEmail/ChangeEmailButton";
import ChangePasswordButton from "../../components/forms/ChangePassword/ChangePasswordButton";
import Infobox from "../../components/Infobox/Infobox";
import DeleteAccountButton from "../../components/forms/DeleteAccount/DeleteAccountButton";

export default function Settings() {
  const { userData, isLoading, error } = useUser();

  if (isLoading) {
    <Loading></Loading>;
  }

  if (error) {
    <div>Error loading user data: {error.message}</div>;
  }

  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      event.preventDefault();
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return (
    <Page>
      <Wrapper>
        <div className={styles.settings}>
          <Header>Ustawienia konta</Header>
          <div className={styles.settings__section}>
            <div className={styles.settings__subsection}>
              <p>Imię i nazwisko</p>
              <div className={styles.settings__subsection__row}>
                <p>
                  {userData.name} {userData.surname}
                </p>
                <ChangeNameNickButton label={"imię i nazwisko"} />
              </div>
              <p>Nazwa użytkownika</p>
              <div className={styles.settings__subsection__row}>
                <p>{userData.username}</p>
                <ChangeNameNickButton label={"nazwę użytkownika"} />
              </div>
              <p>Adres e-mail</p>
              <div className={styles.settings__subsection__row}>
                <p>{userData.email} </p>
                <ChangeEmailButton label={"aders e-mail"} />
              </div>
              <p>Hasło</p>
              <div className={styles.settings__subsection__row}>
                <p className={styles.password}>........</p>
                <ChangePasswordButton label={"hasło"} />
              </div>

              <DeleteAccountButton
                label={"Czy na pewno chcesz usunąć konto?"}
              ></DeleteAccountButton>
            </div>
          </div>
        </div>
      </Wrapper>
    </Page>
  );
}
