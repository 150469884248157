import styles from "./start.module.scss";
import Button from "../../components/forms/Button/Button";
import { ReactComponent as GaoIcon } from "../../assets/images/gao.svg";
import { MdLogin, MdLogout } from "react-icons/md";
import DeafultUser from "../../assets/images/user_grey.svg";
import { useEffect } from "react";
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";

export default function Start() {
  const { checkIsLoggedIn } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    checkIsLoggedIn();
    if (localStorage.getItem("token") === "true") {
      navigate("/gaoanimalcenter");
    }
  });

  return (
    <div className={styles.start}>
      <div className={styles.column}>
        <GaoIcon width={124} height={124} />
        <p className={styles.title}>GAO Animal Center</p>
      </div>
      <div className={styles.row}>
        <Button route="/login">
          <MdLogin />
          Zaloguj się
        </Button>
        <Button route="/register">
          <MdLogout />
          Zarejestruj się
        </Button>
      </div>
      <Button route="/gaoanimalcenter">
        <img
          className={styles.start__profileImg}
          src={DeafultUser}
          alt="zdjęcie niezalogowanego użytkownia"
        />
        Kontynuuj jako gość
      </Button>
    </div>
  );
}
