const breedFilters = [
  "Brak filtrów",
  "psy",
  "koty",
  "ptaki",
  "gryzonie",
  "konie",
  "zwierzęta egzotyczne",
  "zwierzęta gospodarskie",
  "drób",
  "króliki",
  "zwierzęta futerkowe",
  "ryby hodowlane",
  "ryby akwariowe",
];
const serviceFilters = [
  "Brak filtrów",
  "Usługi weterynaryjne",
  "Codzienna opieka i pielęgnacja",
  "Usługi żywieniowe",
  "Szkolenie i zachowanie",
  "Usługi specjalistyczne",
  "Transport",
  "Inne",
];
const sellerFilters = [
  "Brak filtrów",
  "Sklepy zoologiczne",
  "Specjalistyczne sklepy dla zwierząt",
  "Sklepy z karmą dla zwierząt",
  "Inne",
];
const statesFilters = [
  "Brak filtrów",
  "dolnośląskie",
  "kujawsko-pomorskie",
  "lubelskie",
  "lubuskie",
  "łódzkie",
  "małopolskie",
  "mazowieckie",
  "opolskie",
  "podkarpackie",
  "podlaskie",
  "pomorskie",
  "śląskie",
  "świętokrzyskie",
  "warmińsko-mazurskie",
  "wielkopolskie",
  "zachodniopomorskie",
];

//
const breedTypes = [
  "psy",
  "koty",
  "ptaki",
  "gryzonie",
  "konie",
  "zwierzęta egzotyczne",
  "zwierzęta gospodarskie",
  "drób",
  "króliki",
  "zwierzęta futerkowe",
  "ryby hodowlane",
  "ryby akwariowe",
];
const serviceTypes = [
  "Usługi weterynaryjne",
  "Codzienna opieka i pielęgnacja",
  "Usługi żywieniowe",
  "Szkolenie i zachowanie",
  "Usługi specjalistyczne",
  "Transport",
  "Inne",
];
const sellerTypes = [
  "Sklepy zoologiczne",
  "Specjalistyczne sklepy dla zwierząt",
  "Sklepy z karmą dla zwierząt",
  "Inne",
];
const statesTypes = [
  "dolnośląskie",
  "kujawsko-pomorskie",
  "lubelskie",
  "lubuskie",
  "łódzkie",
  "małopolskie",
  "mazowieckie",
  "opolskie",
  "podkarpackie",
  "podlaskie",
  "pomorskie",
  "śląskie",
  "świętokrzyskie",
  "warmińsko-mazurskie",
  "wielkopolskie",
  "zachodniopomorskie",
];

export {
  breedTypes,
  serviceTypes,
  sellerTypes,
  statesTypes,
  breedFilters,
  serviceFilters,
  statesFilters,
  sellerFilters,
};
