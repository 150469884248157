import React from "react";
import { Route, Routes } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute"; // Adjust import path as necessary
import { routes } from "./routes"; // Adjust import path as necessary
const Router: React.FC = () => {
  return (
    <>
      <Routes>
        {routes.map(
          (route: {
            path: string;
            component: React.ElementType;
            isPrivate: boolean;
          }) => (
            <Route
              path={route.path}
              element={
                <ProtectedRoute
                  element={<route.component />}
                  isPrivate={route.isPrivate}
                />
              }
              key={route.path}
            />
          ),
        )}
      </Routes>
    </>
  );
};

export default Router;
