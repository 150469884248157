import styles from "./signup.module.scss";
import { Link } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ReactComponent as GaoIcon } from "../../assets/images/gao.svg";
import InputField from "../../components/forms/InputField/InputField";
import { error } from "console";
import { url } from "../../url";
import { useAuth } from "../../context/AuthContext";
import { useUser } from "../../hooks/useUser";
import Loading from "components/loading/BetterLoading";

const signupUser = async (user: any) => {
  let response;
  try {
    response = await axios.post(url + "/auth/signup", user, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (error: any) {
    response = error.response;
    console.log(response);
  }
  return response.data;
};
export default function Signup() {
  const { login } = useAuth();
  const { updateUserData } = useUser();
  const [formData, setFormData] = useState({
    name: "",
    surname: "",
    username: "",
    email: "",
    password: "",
    repeatPassword: "",
  });
  const [emailError, setEmailError] = useState<string>("");
  const [passwordError, setPasswordError] = useState<string>("");
  const [nameError, setNameError] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    // if (name === "name" && value.length < 3) {
    // 	setNameError("Nazwa użytkownika musi mieć minumum 3 znaki");
    // } else {
    // 	setNameError("");
    // }
  };

  const { mutate } = useMutation({
    mutationFn: signupUser,
    onSuccess: (data: any) => {
      console.log(data);
      if (data.message === "Username is already taken") {
        setNameError("Ta nazwa użytkownika jest zajęta!");
        return;
      } else if (data.message === "Email is already taken") {
        setEmailError("To konto już istnieje!");
        return;
      } else {
        navigate("/login");
      }
    },
  });

  const handleSignupClick = (event: any) => {
    setNameError("");
    setEmailError("");
    setPasswordError("");
    if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(formData.email)) {
      setEmailError("Prosze podać poprawny adres email.");
      event.preventDefault();
      return;
    }
    if (formData.password.length < 8) {
      setPasswordError("Hasło musi mieć minimum 8 znaków");
      event.preventDefault();
      return;
    }

    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])/;
    if (!passwordRegex.test(formData.password)) {
      setPasswordError(
        "Hasło musi zawierać dużą, małą literę, cyfrę oraz znak specjalny.",
      );
      event.preventDefault();
      return;
    }

    if (formData.password !== formData.repeatPassword) {
      setPasswordError("Hasła nie są takie same");
      event.preventDefault();
      return;
    }
    setIsLoading(true);
    mutate(formData, {
      onSettled: () => {
        setIsLoading(false);
      },
    });
    event.preventDefault();
  };

  useEffect(() => {
    if (localStorage.getItem("token") === "true") {
      navigate("/login");
    }
  }, [navigate]);

  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      event.preventDefault();
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.box}>
        <div className={styles.box__heroImg}></div>
        <div className={styles.box__formBox}>
          <GaoIcon width={124} height={124} />
          <form onSubmit={handleSignupClick}>
            <h2 className={styles.box__brandName}>Gao Animal Center</h2>
            <p className={styles.box__formName}>Rejestracja</p>
            <div className={styles.box__nameBox}>
              <InputField
                label={"Imię"}
                name={"name"}
                value={formData.name}
                onChange={handleChange}
                error={""}
                type={"text"}
                required
                length={40}
              ></InputField>
              <InputField
                label={"Nazwisko"}
                name={"surname"}
                value={formData.surname}
                onChange={handleChange}
                error={""}
                type={"text"}
                required
                length={50}
              ></InputField>
            </div>
            <InputField
              label={"Nazwa użytkownika"}
              name={"username"}
              value={formData.username}
              onChange={handleChange}
              error={nameError}
              type={"text"}
              required
              length={50}
            ></InputField>
            <InputField
              label={"E-mail"}
              name={"email"}
              value={formData.email}
              onChange={handleChange}
              error={emailError}
              type={"text"}
              required
              length={254}
            ></InputField>
            <InputField
              label={"Hasło"}
              name={"password"}
              value={formData.password}
              onChange={handleChange}
              error={passwordError}
              type={"password"}
              required
              length={128}
            ></InputField>
            <InputField
              label={"Powtórz hasło"}
              name={"repeatPassword"}
              value={formData.repeatPassword}
              onChange={handleChange}
              error={passwordError}
              type={"password"}
              required
              length={128}
            ></InputField>
            <div className={styles.box__formBtnBox}>
              <button
                type="submit"
                className={styles.box__formBtn}
                disabled={isLoading}
              >
                {isLoading ? (
                  <Loading></Loading>
                ) : (
                  <>
                    <span>Z</span>
                    <span>a</span>
                    <span>r</span>
                    <span>e</span>
                    <span>j</span>
                    <span>e</span>
                    <span>s</span>
                    <span>t</span>
                    <span>r</span>
                    <span>u</span>
                    <span>j</span> <span>s</span>
                    <span>i</span>
                    <span>ę</span>
                  </>
                )}
              </button>
            </div>
            <div className={styles.box__formNoAccountBox}>
              <p className={styles.box__formNoAccount}>
                Masz już konto?{" "}
                <Link className={styles.box__formNoAccountLink} to="/login">
                  Zaloguj się.
                </Link>
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
