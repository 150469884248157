import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import defaultPet from "assets/images/gao.svg";
import axios from "axios";
import InputField from "components/forms/InputField/InputField";
import ImageSlider from "components/ImageSlider/ImageSlider";
import Loading from "components/loading/BetterLoading";
import { useEffect, useRef, useState } from "react";
import { IoClose } from "react-icons/io5";
import { LuFileEdit, LuTrash2 } from "react-icons/lu";
import { url } from "../../../url";
import styles from "./mypets.module.scss";
import { useUser } from "hooks/useUser";
interface MyPetsInterface {
  petType: string;
  heading: string;
}

const getPets = async (type: string) => {
  let response;
  try {
    response = await axios.get(`${url}/${type}/owner/my`, {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    });
  } catch (error: any) {
    response = error.response;
    console.log(response);
  }
  if (type === "dog") return response.data.dogIDs;
  if (type === "cat") return response.data.catIDs;
};

const updatePet = async ({
  type,
  id,
  data,
}: {
  type: string;
  id: string;
  data: any;
}) => {
  let response;
  try {
    response = await axios.patch(`${url}/${type}/${id}`, data, {
      withCredentials: true,
    });
  } catch (error: any) {
    response = error.response;
    console.log(response);
  }
  return response.data;
};
const deletePet = async ({ type, id }: { type: string; id: string }) => {
  let response;
  console.log(`${url}/${type}/${id}`);
  try {
    response = await axios.delete(`${url}/${type}/${id}`, {
      withCredentials: true,
    });
  } catch (error: any) {
    response = error.response;
    console.log(response);
  }
  return response.data;
};
export default function MyPets({ petType, heading }: MyPetsInterface) {
  const dialogRef = useRef<HTMLDialogElement | null>(null);
  const [editError, setEditError] = useState<string>("");
  const {setDataChangeStatus, setIsError} = useUser(); 
  const [orginalData, setOrginalData] = useState({
    chipNumber: "",
    dateOfBirth: "",
    imageUrls: [""],
    name: "",
    origin: "",
    owner: "",
    ownerMail: "",
    ownerUsername: "",
    vet: "",
    __v: undefined,
    _id: "",
  });
  const [dialogData, setDialogData] = useState({
    chipNumber: "",
    dateOfBirth: "",
    imageUrls: [""],
    name: "",
    origin: "",
    owner: "",
    ownerMail: "",
    ownerUsername: "",
    vet: "",
    __v: undefined,
    _id: "",
  });

  const queryClient = useQueryClient();
  const today_date = new Date().toISOString().split("T")[0];
  const { data, isLoading, error } = useQuery({
    queryKey: [`${petType}`],
    queryFn: () => getPets(`${petType}`),
  });

  const { mutate: mutateDelete } = useMutation({
    mutationKey: [`${petType}`],
    mutationFn: deletePet,
    onSuccess: (data: any) => {
      console.log(data);
      setIsError(false);
      setDataChangeStatus("Twój zwierzak został usunięty")
      queryClient.invalidateQueries({ queryKey: [`${petType}`] });
    },
  });

  const { mutate: mutateUpdate } = useMutation({
    mutationKey: [`${petType}`],
    mutationFn: updatePet,
    onSuccess: (data: any) => {
      if (
        data.message ===
        `${petType.charAt(0).toUpperCase() + petType.slice(1)}ID ${orginalData._id} updated successfully`
      ) {
        closeDialog();
        setIsError(false);
        setDataChangeStatus("Dane zwierzaka zostały zmienione")
        queryClient.invalidateQueries({ queryKey: [`${petType}`] });
      } else if (data.message.includes("dup key")) {
        setEditError(`Już istnieje taki ${petType === "dog" ? "pies" : "kot"}`);
      } else {
        setEditError("Wystąpił błąd");
      }
    },
  });

  const handleDialogDataChange = (event: any) => {
    const { name, value } = event.target;
    setDialogData((prevDialogData) => ({
      ...prevDialogData,
      [name]: value,
    }));
  };

  const compareData = (dialogData: any, orginalData: any) => {
    const patchData: any = {};
    for (const key in orginalData) {
      if (orginalData[key] !== dialogData[key]) {
        patchData[key] = dialogData[key];
      }
    }
    return patchData;
  };

  const handleEditSubmit = (event: any) => {
    event.preventDefault();
    const patchData = compareData(dialogData, orginalData);
    if (Object.keys(patchData).length !== 0) {
      if (
        patchData["chipNumber"]?.length &&
        patchData["chipNumber"]?.length !== 15
      ) {
        setEditError("Numer czipu musi mieć 15 cyfr");
        return;
      }
      mutateUpdate({ type: petType, id: orginalData._id, data: patchData });
    } else {
      setEditError("Nic nie zostało zmienione");
    }
  };

  const closeDialog = () => {
    if (!dialogRef.current) {
      return;
    }
    setDialogData({
      chipNumber: "",
      dateOfBirth: "",
      imageUrls: [""],
      name: "",
      origin: "",
      owner: "",
      ownerMail: "",
      ownerUsername: "",
      vet: "",
      __v: undefined,
      _id: "",
    });
    setOrginalData({
      chipNumber: "",
      dateOfBirth: "",
      imageUrls: [""],
      name: "",
      origin: "",
      owner: "",
      ownerMail: "",
      ownerUsername: "",
      vet: "",
      __v: undefined,
      _id: "",
    });
    document.body.style.paddingRight = "unset";
    dialogRef.current?.close();
  };
  
  useEffect(() => {
    if (!dialogData._id) return;
    dialogRef.current?.showModal();
    dialogRef.current?.addEventListener("close", closeDialog);
    return () => {
      dialogRef.current?.removeEventListener("close", closeDialog);
    };
  }, [dialogData]);

  useEffect(() => {
    const errorTimeout = setTimeout(() => {
      setEditError("");
    }, 3000);
    return () => clearTimeout(errorTimeout);
  }, [editError]);

  return (
    <>
      <dialog className={styles.dialog} ref={dialogRef}>
        {dialogData._id && (
          <div className={styles.dialogBox}>
            <button className={styles.closeBtn} onClick={() => closeDialog()}>
              <IoClose className={styles.closeIcon} />
            </button>
            <form onSubmit={handleEditSubmit}>
              <InputField
                label={"Imię"}
                name={"name"}
                value={dialogData.name}
                onChange={handleDialogDataChange}
                error={""}
                type={"text"}
                required
                length={40}
              />
              <InputField
                label={"Data urodzenia"}
                name={"dateOfBirth"}
                value={dialogData.dateOfBirth.split("T")[0]}
                onChange={handleDialogDataChange}
                error={""}
                type={"date"}
                required
                length={10}
                max={today_date}
              />
              <InputField
                label={"Numer chipu"}
                name={"chipNumber"}
                value={dialogData.chipNumber}
                onChange={handleDialogDataChange}
                error={""}
                type={"number"}
                required={false}
                length={15}
              />
              <InputField
                label={"Pochodzenie"}
                name={"origin"}
                value={dialogData.origin}
                onChange={handleDialogDataChange}
                error={""}
                type={"text"}
                required
                length={58}
              />
              <InputField
                label={"Weterynarz"}
                name={"vet"}
                value={dialogData.vet}
                onChange={handleDialogDataChange}
                error={""}
                type={"text"}
                required
                length={50}
              />
              <button className={styles.editBtn} type="submit">
                Zapisz zmiany
              </button>
            </form>
            {editError && <p className={styles.error}>{editError}</p>}
          </div>
        )}
      </dialog>
      <div className={styles.box}>
        <h3 className={styles.heading}>{heading}</h3>
        <div className={styles.cardsBox}>
          {!isLoading ? (
            data?.length ? (
              data.map((item: any, index: number) => (
                <div className={styles.card} key={item._id}>
                  <div className={styles.imgBox}>
                    <ImageSlider
                      imageUrls={item.imageUrls || []}
                      imageAlt={"zdjęcia zwierzaka"}
                      borderRadius="50%"
                      small={true}
                    />
                  </div>
                  <div className={styles.btnBox}>
                    <button
                      className={styles.btnDelete}
                      onClick={() => {
                        mutateDelete({ type: petType, id: item._id });
                      }}
                    >
                      <LuTrash2 />
                    </button>
                    <button
                      className={styles.btnEdit}
                      onClick={() => {
                        setOrginalData(item);
                        setDialogData(item);
                      }}
                    >
                      <LuFileEdit />
                    </button>
                  </div>
                  <div className={styles.infoBox}>
                    <p
                      className={styles.info}
                      style={{ fontWeight: "bold", fontSize: "1.6rem" }}
                    >
                      {item.name}
                    </p>
                    <p className={styles.info}>
                      {item.dateOfBirth.split("T")[0]}
                    </p>
                    <p className={styles.info}>{item.chipNumber}</p>
                  </div>
                </div>
              ))
            ) : (
              <div className={styles.noPetsBox}>
                <p>
                  Brak zarejestrowanych {petType === "dog" ? "psów" : "kotów"}
                </p>
              </div>
            )
          ) : (
            <Loading />
          )}
        </div>
      </div>
    </>
  );
}
