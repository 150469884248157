import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import ImageSlider from "components/ImageSlider/ImageSlider";
import Page from "components/page/Page";
import Wrapper from "components/wrapper/Wrapper";
import { useEffect, useRef, useState } from "react";
import { FaStar, FaTrash } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import defaultImage from "../../assets/images/user.svg";
import BetterLoading from "../../components/loading/BetterLoading";
import { useUser } from "../../hooks/useUser";
import { url } from "../../url";
import styles from "./singlebusiness.module.scss";
interface CommentData {
  rating: number | undefined;
  comment: string;
}

const collectData = async (call: string, id: string) => {
  let response;
  try {
    response = await axios.get(url + `${call}/${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (error: any) {
    response = error.response;
    console.log(response);
  }
  if (call === "/breeder") return response.data.breeder;
  else if (call === "/seller") return response.data.seller;
  else return response.data.serviceProvider;
};

const collectRatings = async (call: string, id: string) => {
  let response;
  try {
    response = await axios.get(url + `${call}/${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (error: any) {
    response = error.response;
    console.log(response);
  }
  return response.data.ratings;
};

const postRating = async ({
  call,
  id,
  token,
  data,
}: {
  call: string;
  id: string;
  token: string;
  data: CommentData;
}) => {
  let response;
  try {
    response = await axios.post(url + `${call}/${id}`, data, {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    });
  } catch (error: any) {
    response = error.response;
    console.log(response);
  }
  return response.data;
};

const deleteRating = async ({
  call,
  ratingId,
  token,
}: {
  call: string;
  ratingId: string;
  token: string;
}) => {
  let response;
  try {
    response = await axios.delete(url + `${call}/${ratingId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      withCredentials: true,
    });
  } catch (error: any) {
    response = error.response;
    console.log(response);
  }
  return response.data;
};

export default function SingleBusiness() {
  const [hoveredStar, setHoveredStar] = useState<number | null>(null);
  const [selectedStar, setSelectedStar] = useState<number | null>(null);
  const dialogRef = useRef<HTMLDialogElement>(null);
  const formRef = useRef<HTMLFormElement>(null);
  const [item, setItem] = useState<any>({});
  const [ratings, setRatings] = useState<any>([]);
  const { userData } = useUser();
  const [commentData, setCommentData] = useState<CommentData>({
    rating: undefined,
    comment: "",
  });
  const [commentSuccess, setCommentSuccess] = useState<string>("");
  const [commentError, setCommentError] = useState<string>("");

  const navigate = useNavigate();
  const { id } = useParams();

  const queryClient = useQueryClient();

  const location = useLocation();
  const { state } = location;

  const pathname = location.pathname;
  const dataType = pathname.includes("/kennels") ? "kennel" : "servProv";
  const dataType2 = pathname.includes("/kennels")
    ? "kennel"
    : pathname.includes("/pet_services")
      ? "servProv"
      : "seller";
  const callType = {
    kennel: `/breeder`,
    servProv: `/serviceProvider`,
    seller: `/seller`,
  };

  const ratingCallType = {
    kennel: `/breeder/ratings`,
    servProv: `/serviceProvider/ratings`,
  };

  const postCallType = {
    kennel: "/breeder/rating",
    servProv: "/serviceProvider/rating",
  };

  const deleteCallType = {
    kennel: "/breeder/rating",
    servProv: "/serviceProvider/rating",
  };

  const closeDialog = () => {
    if (!dialogRef.current) {
      return;
    }
    dialogRef.current.close();
    navigate(
      dataType === "kennel"
        ? "/kennels"
        : dataType === "servProv"
          ? "/pet_services"
          : "/seller",
    );
  };

  const handleCommentChange = (event: any) => {
    const { name, value } = event.target;
    setCommentData((prevCommentData) => ({
      ...prevCommentData,
      [name]: value,
    }));
  };
  const handleCommentRateChange = (key: string, value: string) => {
    setCommentData((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };
  const handleCommentSubmit = async (event: any) => {
    const token = localStorage.getItem("token");
    if (!selectedStar) {
      setCommentError("Musisz wystawić ocenę");
      event.preventDefault();
      return;
    }
    if (token && id) {
      mutate({
        call: postCallType[dataType],
        id: id,
        token: token,
        data: commentData,
      });
    }
    event.preventDefault();
  };

  const handleDeleteRating = async (ratingId: string) => {
    const token = localStorage.getItem("token");
    if (token && ratingId) {
      deleteMutate({
        call: deleteCallType[dataType],
        ratingId: ratingId,
        token: token,
      });
    }
  };

  const { data, isLoading, error } = useQuery({
    queryKey: [`${id}`],
    queryFn: async () => {
      if (id) {
        const result = await collectData(callType[dataType], id);
        return result;
      }
    },
  });

  const {
    data: ratingsData,
    isLoading: isLoadingRatings,
    error: errorRatings,
    refetch,
  } = useQuery({
    queryKey: [`ratings ${id}`],
    queryFn: async () => {
      if (id) {
        const result = await collectRatings(ratingCallType[dataType], id);
        return result;
      }
    },
  });

  const { mutate } = useMutation({
    mutationKey: [`rating ${id}`],
    mutationFn: postRating,
    onSuccess: (data: any) => {
      if (data.message === "Only one rating per user") {
        setCommentError("Dodałeś już komentarz");
      } else if (data.message === "Cannot rate own business") {
        setCommentError("To twoja hodowla");
      }
      if (data.message === "Rating created successfully") {
        setCommentSuccess("Opinia została dodana");
        setCommentData({
          ...commentData,
          rating: undefined,
          comment: "",
        });
        formRef.current?.reset();
        setSelectedStar(null);
        queryClient.invalidateQueries({ queryKey: [`ratings ${id}`] });
      }
      console.log(data.message);
    },
  });

  const { mutate: deleteMutate } = useMutation({
    mutationFn: deleteRating,
    onSuccess: (data: any) => {
      setRatings((prevRatings: any[]) =>
        prevRatings.filter((rating: any) => rating._id !== data._id),
      );
      queryClient.invalidateQueries({ queryKey: [`ratings ${id}`] });
    },
  });

  useEffect(() => {
    if (data) {
      setItem(data);
    }
  }, [data]);

  useEffect(() => {
    if (ratingsData) {
      setRatings(ratingsData);
    }
  }, [ratingsData]);

  useEffect(() => {
    if (!dialogRef.current) {
      return;
    }
    dialogRef.current.showModal();

    dialogRef.current.addEventListener("close", closeDialog);
    return () => {
      dialogRef.current?.removeEventListener("close", closeDialog);
    };
  }, [pathname]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setCommentSuccess("");
    }, 3000);
    return () => clearTimeout(timer);
  }, [commentSuccess]);
  useEffect(() => {
    const timer = setTimeout(() => {
      setCommentError("");
    }, 3000);
    return () => clearTimeout(timer);
  }, [commentError]);

  if (error) {
    return <div>{error.message}</div>;
  }
  return (
    <>
      {(dataType === "kennel" || dataType === "servProv") && (
        <Page>
          <Wrapper>
            <div className={styles.box}>
              {!isLoading ? (
                <div className={styles.card}>
                  <div className={styles.topContentBox}>
                    <div className={styles.imgsBox}>
                      <ImageSlider
                        imageUrls={item.imageUrls || []}
                        imageAlt={
                          dataType === "kennel"
                            ? "zdjęcia hodowli"
                            : "zdjęcia usługi"
                        }
                        objectFit="contain"
                        businessType={
                          dataType === "kennel" ? "kennel" : "serviceProvider"
                        }
                      />
                    </div>
                    <div className={styles.contentBox}>
                      <h2 className={styles.cardName}>
                        {dataType === "kennel" ? item.name : item.title}
                      </h2>
                      <p className={styles.box__info}>{item.type}</p>
                      <div className={styles.box__contentInfoBox}>
                        <h3 className={styles.box__headerInfo}>Kontakt</h3>
                        <p className={styles.box__info}>{item.email}</p>
                        <p className={styles.box__info}>{item.phone}</p>
                        {dataType === "servProv" && (
                          <p className={styles.box__info}>{item.link}</p>
                        )}
                      </div>
                      <div className={styles.box__contentInfoBox}>
                        <h3 className={styles.box__headerInfo}>Lokalizacja</h3>

                        <div>
                          <p className={styles.box__info}>{item.street}</p>
                          <p className={styles.box__info}>/ {item.apartment}</p>
                        </div>
                        <div>
                          <p className={styles.box__info}>{item.zipCode}</p>
                          <p className={styles.box__info}>{item.city}</p>
                        </div>

                        <p className={styles.box__info}>{item.state}</p>
                        <p className={styles.box__info}>{item.country}</p>
                      </div>
                    </div>
                  </div>

                  <div className={styles.box__infoBox}>
                    <h3 className={styles.box__headerInfo}>Opis</h3>
                    <p className={styles.box__info}>{item.description}</p>
                  </div>

                  <div className={styles.box__infoBox}>
                    <h3 className={styles.box__headerInfo}>Opinie</h3>

                    <div className={styles.box__textAreaBox}>
                      <p
                        className={styles.box__info}
                        style={{ marginBottom: "2rem" }}
                      >
                        {dataType === "kennel"
                          ? "Co sądzisz o tej hodowli? Podziel się swoją opinią z innymi!"
                          : "Co sądzisz o tych usługach? Podziel się swoją opinią z innymi!"}
                      </p>
                      <form ref={formRef} onSubmit={handleCommentSubmit}>
                        <textarea
                          className={styles.box__textArea}
                          name="comment"
                          value={commentData.comment}
                          minLength={5}
                          maxLength={1000}
                          placeholder="Dodaj opinię..."
                          onChange={handleCommentChange}
                          required
                        ></textarea>
                        <div className={styles.box__ratingsBox}>
                          <p className={styles.box__ratingInfo}>Wystaw ocenę</p>
                          {Array.from({ length: 5 }, (_, index) => (
                            <FaStar
                              key={index}
                              className={styles.ratingStar}
                              style={{
                                color:
                                  index < (hoveredStar ?? selectedStar ?? 0)
                                    ? "gold"
                                    : "gray",
                              }}
                              onMouseEnter={() => setHoveredStar(index + 1)}
                              onMouseLeave={() => setHoveredStar(null)}
                              onClick={() => {
                                handleCommentRateChange(
                                  "rating",
                                  (index + 1).toString(),
                                );
                                setSelectedStar(index + 1);
                              }}
                            />
                          ))}
                          <button
                            className={styles.box__textAreaBtn}
                            type="submit"
                          >
                            Dodaj opinię
                          </button>
                          {commentSuccess && (
                            <p className={styles.box__commentSuccess}>
                              {commentSuccess}
                            </p>
                          )}
                          {commentError && (
                            <p className={styles.box__commentError}>
                              {commentError}
                            </p>
                          )}
                        </div>
                      </form>
                    </div>
                    <div className={styles.box__userCommentsBox}>
                      <div className={styles.box__userCommentsHeaderBox}>
                        <p className={styles.box__userCommentsHeader}>
                          Opinie użytkowników
                        </p>
                      </div>
                      <div className={styles.box__userComments}>
                        {!isLoadingRatings ? (
                          ratings.length ? (
                            ratings.map((rating: any) => (
                              <div
                                key={rating._id}
                                className={styles.box__userCommentBox}
                              >
                                <div
                                  className={styles.box__userCommentHeaderBox}
                                >
                                  <img
                                    className={styles.box__userCommentImg}
                                    src={
                                      rating.author.photo
                                        ? rating.author.photo
                                        : defaultImage
                                    }
                                    alt="zdjęcie użytkownika"
                                  />

                                  <div
                                    className={styles.box__userCommentRatingBox}
                                  >
                                    <p
                                      className={
                                        styles.box__userCommentUsername
                                      }
                                    >
                                      {rating.author?.username}
                                    </p>
                                    <div style={{ display: "flex" }}>
                                      <p
                                        className={
                                          styles.box__userCommentRating
                                        }
                                      >
                                        {Array.from(
                                          { length: rating.rating },
                                          (_, index) => (
                                            <FaStar
                                              key={index}
                                              className={
                                                styles.box__userStarIcon
                                              }
                                            />
                                          ),
                                        )}
                                      </p>
                                      <p
                                        className={styles.box__userCommentDate}
                                      >
                                        {rating.date.split("T")[0]}
                                      </p>
                                      <div>
                                        {rating.author._id === userData._id && (
                                          <FaTrash
                                            className={
                                              styles.box__userCommentTrashIcon
                                            }
                                            onClick={() =>
                                              handleDeleteRating(rating._id)
                                            }
                                            style={{
                                              cursor: "pointer",
                                              marginLeft: "10px",
                                            }}
                                          />
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <p className={styles.box__userComment}>
                                  {rating.comment}
                                </p>
                              </div>
                            ))
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <p
                                className={styles.box__textAreaInfo}
                                style={{
                                  textAlign: "center",
                                  fontSize: "1.6rem",
                                }}
                              >
                                Brak komentarzy... <br />
                                Bądź pierwszy!
                              </p>
                            </div>
                          )
                        ) : (
                          <BetterLoading />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <BetterLoading />
              )}
            </div>
          </Wrapper>
        </Page>
      )}
    </>
  );
}
