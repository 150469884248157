import { useState } from "react";
import { FaArrowAltCircleRight } from "react-icons/fa";
import { FaRegAddressCard } from "react-icons/fa6";
import { LuDog, LuHome, LuUser2 } from "react-icons/lu";
import { MdKeyboardArrowDown } from "react-icons/md";
import { Link, useLocation } from "react-router-dom";
import { ReactComponent as GaoIcon } from "../../../assets/images/gao.svg";
import DefaultUser from "../../../assets/images/user.svg";
import { useAuth } from "../../../context/AuthContext";
import { useUser } from "../../../hooks/useUser";
import LoginComponent from "../../forms/LoginComponent/LoginComponent";
import LogoutComponent from "../../forms/LogoutComponent/LogoutComponent";
import Dropdown from "../Dropdown/Dropdown";
import styles from "../Sidebar/sidebar.module.scss";
import Feedback from "components/Feedback/Feedback";
import Infobox from "components/Infobox/Infobox";

export default function Sidebar() {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [dropAnimal, setDropAnimal] = useState<boolean>(false);
  const [dropRegistration, setDropRegistration] = useState<boolean>(false);
  const location = useLocation();
  const pathname = location.pathname;
  const token = localStorage.getItem("token");
  const { userData, getUser, isLoading, error } = useUser();
  const [showFirst, setShowFirst] = useState(true);
  const { isLoggedIn, checkIsLoggedIn } = useAuth();
  const accType = userData.accountType;
  const photo = userData.photo ? userData.photo : DefaultUser;

  let type = "";

  if (accType.type === "standard") {
    type = "Standardowe";
  }
  if (accType.type === "breeder") {
    type = "Hodowca";
  }
  if (accType.type === "seller") {
    type = "Sprzedawca";
  }
  if (accType.type === "service_provider") {
    type = "Usługodawca";
  }
  return (
    <>
      <nav
        className={styles.sidebar}
        style={{ transform: isOpen ? "translateX(0)" : "" }}
      >
        <div className={styles.sidebar__burgerBtnBox}>
          <button
            onClick={() => setIsOpen(!isOpen)}
            className={styles.sidebar__burgerBtn}
            style={{
              transform: isOpen ? "rotate(-180deg) translateX(20px)" : "",
              boxShadow: isOpen ? "2px -2px 3px rgba(0,0,0,.3" : "",
            }}
          >
            <FaArrowAltCircleRight className={styles.sidebar__burgerBtnIcon} />
          </button>
        </div>
        <div className={styles.scroll}>
          <div className={styles.sidebar__brandNameBox}>
            <div>
              <GaoIcon width={48} height={48} />
            </div>
            <h3 className={styles.sidebar__brandName}>Gao Animal Center</h3>
          </div>
          <div className={styles.sidebar__profileBox}>
            <Link className={styles.sidebar__profileImgBox} to="/profile">
              <img
                className={styles.sidebar__profileImg}
                src={`${isLoggedIn ? photo : DefaultUser}`}
                alt="zdjęcie profilowe użytkownika"
              />
            </Link>
            <div className={styles.column}>
              {isLoggedIn ? (
                <>
                  <p className={styles.sidebar__profileName}>
                    Witaj, {userData.username}
                  </p>
                  <p className={styles.sidebar__profileEmail}>
                    {userData.email}
                  </p>
                  <p className={styles.sidebar__profileEmail}>
                    Typ konta: {type}
                  </p>
                </>
              ) : (
                <>
                  <Link className={styles.sidebar__notLogged} to="/login">
                    Zaloguj się
                  </Link>
                  <p className={styles.sidebar__profileEmail}>lub</p>
                  <Link className={styles.sidebar__notLogged} to="/register">
                    Zarejestruj się
                  </Link>
                </>
              )}
            </div>
          </div>
          <div className={styles.sidebar__linksListBox}>
            <div>
              <div className={styles.sidebar__linkBox}>
                <Link
                  className={`${styles.sidebar__link} ${
                    pathname === "/gaoanimalcenter"
                      ? styles.sidebar__linkActive
                      : ""
                  }`}
                  to="/gaoanimalcenter"
                >
                  <LuHome className={styles.sidebar__linkIcon} />
                  Strona główna
                </Link>
              </div>
              <div className={styles.sidebar__linkBox}>
                <Link
                  className={`${styles.sidebar__link} ${pathname === "/profile" ? styles.sidebar__linkActive : ""}`}
                  to="/profile"
                >
                  <LuUser2 className={styles.sidebar__linkIcon} />
                  Profil
                </Link>
              </div>
              <div className={styles.sidebar__linkBox}>
                <button
                  className={styles.sidebar__button}
                  onClick={() => setDropAnimal(!dropAnimal)}
                >
                  <div className={styles.sidebar__container}>
                    <LuDog className={styles.sidebar__linkIcon} />
                    Zwierzęta
                  </div>
                  <MdKeyboardArrowDown
                    className={`${styles.sidebar__iconDropdown} ${
                      dropAnimal ? styles.sidebar__iconDropdownRotate : ""
                    }`}
                  />
                </button>

                <Dropdown
                  items={["Psy", "Koty"]}
                  paths={["/dogs", "/cats"]}
                  drop={dropAnimal}
                />
              </div>
              <div className={styles.sidebar__linkBox}>
                <button
                  className={`${styles.sidebar__button} ${
                    pathname === "/registration"
                      ? styles.sidebar__linkActive
                      : ""
                  }`}
                  onClick={() => setDropRegistration(!dropRegistration)}
                >
                  <div className={styles.sidebar__container}>
                    <FaRegAddressCard className={styles.sidebar__linkIcon} />
                    Rejestracja
                  </div>
                  <MdKeyboardArrowDown
                    className={`${styles.sidebar__iconDropdown} ${
                      dropRegistration ? styles.sidebar__iconDropdownRotate : ""
                    }`}
                  />
                </button>
                <Dropdown
                  items={[
                    "Rejestracja psa",
                    "Rejestracja kota",
                    "Rejestracja działalności",
                  ]}
                  paths={[
                    "/registration/dog",
                    "/registration/cat",
                    "/registration/business",
                  ]}
                  drop={dropRegistration}
                />
              </div>
            </div>
            {isLoggedIn ? (
              <LogoutComponent></LogoutComponent>
            ) : (
              <LoginComponent></LoginComponent>
            )}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "2%",
            }}
          >
          </div>
          <Feedback/>
        </div>
      </nav>
    </>
  );
}
