import React from "react";
import { useAuth } from "../../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import styles from "./logoutComponent.module.scss";
import { LuLogOut } from "react-icons/lu";

export default function LogoutComponent() {
  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate("/gaoanimalcenter");
  };

  return (
    <div onClick={handleLogout} className={styles.logout}>
      <LuLogOut className={styles.logout__linkIcon} />
      <p>Wyloguj się</p>
    </div>
  );
}
