import { wait } from "@testing-library/user-event/dist/utils";
import axios from "axios";
import { useState } from "react";
import ChangeEmailButton from "../../components/forms/ChangeEmail/ChangeEmailButton";
import ChangeNameNickButton from "../../components/forms/ChangeNameNick/ChangeNameNickButton";
import ChangePasswordButton from "../../components/forms/ChangePassword/ChangePasswordButton";
import DeleteAccountButton from "../../components/forms/DeleteAccount/DeleteAccountButton";
import Header from "../../components/header/Header";
import Loading from "../../components/loading/Loading";
import Page from "../../components/page/Page";
import Wrapper from "../../components/wrapper/Wrapper";
import { useUser } from "../../hooks/useUser";
import { url } from "../../url";
import MyPets from "./MyPets/MyPets";
import styles from "./profile.module.scss";
import Kennel from "./MyBusiness/Kennel/Kennel";
import Seller from "./MyBusiness/Seller/Seller";
import PetServices from "./MyBusiness/PetServices/PetServices";
import { useNavigate } from "react-router-dom";
import ChangePictureButton from "components/forms/ChangePicture/ChangePictureButton";

const handleDelete = async ({ call, id }: { call: string; id: string }) => {
  let response;
  try {
    response = await axios.delete(url + call + id, {
      withCredentials: true,
    });
  } catch (error: any) {
    response = error.response;
    console.log(response);
  }
  return response.data;
};

const handleEdit = async ({
  call,
  id,
  data,
}: {
  call: string;
  id: string;
  data: any;
}) => {
  let response;
  try {
    response = await axios.patch(url + call + id, data, {
      withCredentials: true,
    });
  } catch (error: any) {
    response = error.response;
    console.log(response);
  }
  return response.data;
};

export default function Profile() {
  const { userData, isLoading, error } = useUser();
  const { getUser } = useUser();
  const [heroClass, setHeroClass] = useState<string>("animals");
  const navigate = useNavigate();

  if (isLoading) {
    <Loading></Loading>;
  }

  if (error) {
    <Loading></Loading>;
    wait(2000); // Wait for 2 seconds
    getUser();
  }

  const handleChangeHeroClass = (hero: string) => {
    setHeroClass(hero);
  };
  return (
    <Page>
      <Wrapper>
        <div className={styles.profile}>
          <Header>Profil</Header>
          <div className={styles.profile__heroClassBtnBox}>
            <button
              className={styles.profile__heroClassBtn}
              onClick={() => {
                handleChangeHeroClass("animals");
              }}
              style={{
                transform:
                  heroClass === "animals"
                    ? "translateY(-10px) scale(1.05)"
                    : "",
                backgroundColor: heroClass === "animals" ? "#BBBBD4" : "",
              }}
            >
              Twoje zwierzęta
            </button>
            <button
              className={styles.profile__heroClassBtn}
              onClick={() => {
                handleChangeHeroClass("buisness");
              }}
              style={{
                transform:
                  heroClass === "buisness"
                    ? "translateY(-10px) scale(1.05)"
                    : "",
                backgroundColor: heroClass === "buisness" ? "#BBBBD4" : "",
              }}
            >
              Twoja działalność
            </button>
            <button
              className={styles.profile__heroClassBtn}
              onClick={() => {
                handleChangeHeroClass("settings");
              }}
              style={{
                transform:
                  heroClass === "settings"
                    ? "translateY(-10px) scale(1.05)"
                    : "",
                backgroundColor: heroClass === "settings" ? "#BBBBD4" : "",
              }}
            >
              Ustawienia
            </button>
          </div>
          {heroClass === "animals" && (
            <div className={styles.profileBox__pets}>
              <MyPets petType="dog" heading="Twoje psy" />
              <MyPets petType="cat" heading="Twoje koty" />
            </div>
          )}
          {heroClass === "buisness" && (
            <div className={styles.profileBox}>
              <div>
                {userData.accountType.type === "standard" ? (
                  <div className={styles.profile__column}>
                    <button
                      className={styles.profile__heroClassBtn}
                      onClick={() => {
                        navigate("/registration/business");
                      }}
                    >
                      Zarejestruj działalność
                    </button>
                  </div>
                ) : (
                  <div className={styles.profile__column}>
                    <p className={styles.sectionHeading}>Dane działalności</p>
                    {userData.accountType.type === "breeder" && <Kennel />}
                    {userData.accountType.type === "seller" && <Seller />}
                    {userData.accountType.type === "service_provider" && (
                      <PetServices />
                    )}
                  </div>
                )}{" "}
              </div>
            </div>
          )}
          {heroClass === "settings" && (
            <div className={styles.profileBox}>
              <div className={styles.settings__section}>
                <div className={styles.profile__column}>
                  <p className={styles.sectionHeading}>Ustawienia konta</p>
                </div>
                <div className={styles.settings__subsection}>
                  <div className={styles.settings__row}>
                    <ChangePictureButton label={"zdjęcie profilowe"} />
                  </div>

                  <p>Imię i nazwisko</p>
                  <div className={styles.settings__subsection__row}>
                    <p>
                      {userData.name} {userData.surname}
                    </p>
                    <ChangeNameNickButton label={"imię i nazwisko"} />
                  </div>
                  <p>Nazwa użytkownika</p>
                  <div className={styles.settings__subsection__row}>
                    <p>{userData.username}</p>
                    <ChangeNameNickButton label={"nazwę użytkownika"} />
                  </div>
                  <p>Adres e-mail</p>
                  <div className={styles.settings__subsection__row}>
                    <p>{userData.email} </p>
                    <ChangeEmailButton label={"aders e-mail"} />
                  </div>
                  <p>Hasło</p>
                  <div className={styles.settings__subsection__row}>
                    <p className={styles.password}>........</p>
                    <ChangePasswordButton label={"hasło"} />
                  </div>

                  <DeleteAccountButton
                    label={"Czy na pewno chcesz usunąć konto?"}
                  ></DeleteAccountButton>
                </div>
              </div>
            </div>
          )}
        </div>
      </Wrapper>
    </Page>
  );
}
