import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useState } from "react";
import Dropdown from "../../components/Dropdown/Dropdown";
import InputField from "../../components/forms/InputField/InputField";
import Header from "../../components/header/Header";
import Page from "../../components/page/Page";
import RelativeInfobox from "../../components/RealtiveInfoBox/RelativeInfobox";
import Wrapper from "../../components/wrapper/Wrapper";
import { useUser } from "../../hooks/useUser";
import { url } from "../../url";
import styles from "./businessregistration.module.scss";
import breeder from "./img/breeder.png";
import seller from "./img/seller.png";
import service_provider from "./img/service_provider.png";
import Loading from "components/loading/BetterLoading";

import {
  breedTypes,
  serviceTypes,
  sellerTypes,
  statesTypes,
} from "utils/enums";
interface BreederFormData {
  name: string;
  type: string;
  description: string;
  email: string;
  phone: number | undefined;
  street: string;
  apartment: string;
  city: string;
  state: string;
  zipCode: string;
  country: string;
}

interface ServProvFormData {
  title: string;
  type: string;
  description: string;
  email: string;
  phone: number | undefined;
  street: string;
  apartment: string;
  city: string;
  state: string;
  zipCode: string;
  country: string;
}
interface SellerFormData {
  name: string;
  type: string;
  description: string;
  email: string;
  phone: number | undefined;
  link: string;
  street: string;
  apartment: string;
  city: string;
  state: string;
  zipCode: string;
  country: string;
}
const addBusiness = async ({
  formData,
  call,
  token,
  imgs,
}: {
  formData: BreederFormData | ServProvFormData | SellerFormData;
  call: string;
  token: string;
  imgs: any;
}) => {
  const formatedData = new FormData();
  for (let i = 0; i < imgs.length; i++) {
    formatedData.append("files", imgs[i]);
  }
  formatedData.append("data", JSON.stringify(formData));
  formatedData.forEach((value, key) => {
    console.log(key + ", " + value);
  });
  let response;
  try {
    response = await axios.post(url + `${call}`, formatedData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      withCredentials: true,
    });
  } catch (error: any) {
    response = error.response;
    console.log(response);
  }
  return response.data;
};

export default function BusinessRegistration() {
  const [heroClass, setHeroClass] = useState<string>("breeder");
  const [businessError, setBusinessError] = useState<string>("");
  const [emailError, setEmailError] = useState<string>("");
  const [stateError, setStateError] = useState<string>("");
  const [imgError, setImgError] = useState<string>("");
  const [errorBreedType, setErrorBreedType] = useState<string>("");
  const [errorServiceType, setErrorServiceType] = useState<string>("");
  const [errorSellerType, setErrorSellerType] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { setDataChangeStatus, setIsError, userData } = useUser();
  const { getUser } = useUser();
  const [imgs, setImgs] = useState<File[]>([]);
  const [breederFormData, setBreederFormData] = useState<BreederFormData>({
    name: "",
    type: "",
    description: "",
    email: "",
    phone: undefined,
    street: "",
    apartment: "",
    city: "",
    state: "",
    zipCode: "",
    country: "",
  });
  const [servProvFormData, setServProvFormData] = useState<ServProvFormData>({
    title: "",
    type: "",
    description: "",
    email: "",
    phone: undefined,
    street: "",
    apartment: "",
    city: "",
    state: "",
    zipCode: "",
    country: "",
  });
  const [sellerFormData, setSellerFormData] = useState<SellerFormData>({
    name: "",
    type: "",
    description: "",
    email: "",
    phone: undefined,
    link: "",
    street: "",
    apartment: "",
    city: "",
    state: "",
    zipCode: "",
    country: "",
  });
  const [sellerImgs, setSellerImgs] = useState<File[]>([]);
  const [drop, setDrop] = useState<boolean>(false);
  const [breedType, setBreedType] = useState<string>("Typ hodowli");
  const [serviceType, setServiceType] = useState<string>("Typ usługi");
  const [sellerType, setSellerType] = useState<string>("Typ sklepu");
  const [stateType, setStateType] = useState<string>("Województwo");

  const headerName: any = {
    breeder: "hodowcy",
    service_provider: "usługodawcy",
    seller: "sprzedawcy",
  };
  const buttonName: any = {
    breeder: "hodowcę",
    service_provider: "usługodawcę",
    seller: "sprzedawcę",
  };
  const imgType: any = {
    breeder: breeder,
    service_provider: service_provider,
    seller: seller,
  };
  const formDataType: any = {
    breeder: breederFormData,
    service_provider: servProvFormData,
    seller: sellerFormData,
  };
  const callType: any = {
    breeder: "/breeder",
    service_provider: "/serviceProvider",
    seller: "/seller",
  };

  const handleChangeHeroClass = (hero: string) => {
    setHeroClass(hero);
  };
  const handleBreederChange = (event: any) => {
    const { name, value } = event.target;
    setBreederFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const handleBreederTypeChange = (key: string, value: string) => {
    setBreederFormData((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };
  const handleServProvChange = (event: any) => {
    const { name, value } = event.target;
    setServProvFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const handleServProvTypeChange = (key: string, value: string) => {
    setServProvFormData((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };
  const handleSellerChange = (event: any) => {
    const { name, value } = event.target;
    setSellerFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const handleSellerTypeChange = (key: string, value: string) => {
    setSellerFormData((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };
  const onImageChange = (e: any) => {
    if (Array.from(e.target.files).length > 3) {
      e.preventDefault();
      setImgError("Maksymalna ilość zdjęć to 3");
      setDataChangeStatus("Maksymalna ilość zdjęć to 3");
      setIsError(true);
      return;
    }
    setImgError("");
    setImgs([...e.target.files]);
  };

  const onClick = (e: any) => {
    setImgs([]);
    setDataChangeStatus("Usunięto dodane pliki");
    setIsError(false);
  };
  const { mutate } = useMutation({
    mutationFn: addBusiness,
    onSuccess: (data: any) => {
      console.log(data.message);
      if (data.status === 500) {
        if (
          data.message ===
          "ServiceProvider validation failed: type: `` is not a valid enum value for path `type`., state: `` is not a valid enum value for path `state`."
        ) {
          setStateError("Wybierz województwo");
          setErrorServiceType("Wybierz typ usługi");
        }
        if (
          data.message ===
          "ServiceProvider validation failed: type: `` is not a valid enum value for path `type`."
        ) {
          setErrorServiceType("Wybierz typ usługi");
        }
        if (
          data.message ===
          "ServiceProvider validation failed: state: `` is not a valid enum value for path `state`."
        ) {
          setStateError("Wybierz województwo");
        }
        if (
          data.message ===
          "Seller validation failed: type: `` is not a valid enum value for path `type`."
        ) {
          setErrorSellerType("Wybierz typ sprzedawcy");
        }
        if (
          data.message ===
          "Seller validation failed: type: `` is not a valid enum value for path `type`., state: `` is not a valid enum value for path `state`."
        ) {
          setErrorSellerType("Wybierz typ sprzedawcy");
          setStateError("Wybierz województwo");
        }
        if (
          data.message ===
          "Seller validation failed: state: `` is not a valid enum value for path `state`."
        ) {
          setStateError("Wybierz województwo");
        }
        if (
          data.message ===
          "Breeder validation failed: state: `` is not a valid enum value for path `state`."
        ) {
          setStateError("Wybierz województwo");
        }
        if (
          data.message ===
          "Breeder validation failed: type: `` is not a valid enum value for path `type`."
        ) {
          setErrorBreedType("Wybierz typ hodowcy");
        }
        if (
          data.message ===
          "Breeder validation failed: type: `` is not a valid enum value for path `type`., state: `` is not a valid enum value for path `state`."
        ) {
          setErrorBreedType("Wybierz typ hodowcy");
          setStateError("Wybierz województwo");
        }
      } else {
        getUser();
        setDataChangeStatus(`Udało się zarejestrować ${buttonName[heroClass]}`);
        setIsError(false);
        if (heroClass === "breeder") {
          setBreederFormData((prevFormData) => ({
            ...breederFormData,
            name: "",
            type: "",
            description: "",
            email: "",
            phone: undefined,
            street: "",
            apartment: "",
            city: "",
            state: "",
            zipCode: "",
            country: "",
          }));
        } else if (heroClass === "service_provider") {
          setServProvFormData((prevFormData) => ({
            ...servProvFormData,
            title: "",
            type: "",
            description: "",
            email: "",
            phone: undefined,
            street: "",
            apartment: "",
            city: "",
            state: "",
            zipCode: "",
            country: "",
          }));
        } else {
          setSellerFormData((prevFormData) => ({
            ...sellerFormData,
            name: "",
            type: "",
            description: "",
            email: "",
            phone: undefined,
            link: "",
            street: "",
            apartment: "",
            city: "",
            state: "",
            zipCode: "",
            country: "",
          }));
        }
        setStateType("Województwo");
        setBreedType("Typ hodowcy");
      }
    },
  });

  const handleFormSubmit = async (event: any) => {
    setBusinessError("");
    setEmailError("");
    setStateError("");
    setErrorSellerType("");
    setErrorServiceType("");
    setErrorBreedType("");
    const token = localStorage.getItem("token");

    if (
      !/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(formDataType[heroClass].email)
    ) {
      setEmailError("Prosze podać poprawny adres email.");
      event.preventDefault();
      return;
    }
    if (userData.accountType.type !== "standard") {
      setDataChangeStatus(
        "Nie można zarejstrować więcej niż jednej działalności!",
      );
      setIsError(true);
      event.preventDefault();
      return;
    }
    if (token) {
      setIsLoading(true);
      mutate(
        {
          formData: formDataType[heroClass],
          call: callType[heroClass],
          token: token,
          imgs,
        },
        {
          onSettled: () => {
            setIsLoading(false);
          },
        },
      );
      event.preventDefault();
    }
    event.preventDefault();
  };

  return (
    <Page>
      <Wrapper>
        <div className={styles.box}>
          <Header>Rejestracja</Header>
          <div className={styles.box__cardBox}>
            <div className={styles.box__formBox}>
              <div className={styles.box__heroClassBtnBox}>
                <button
                  className={styles.box__heroClassBtn}
                  onClick={() => {
                    handleChangeHeroClass("breeder");
                    setEmailError("");
                    setBusinessError("");
                    setStateType("Województwo");
                    setImgs([]);
                  }}
                  style={{
                    transform:
                      heroClass === "breeder"
                        ? "translateY(-10px) scale(1.05)"
                        : "",
                    backgroundColor: heroClass === "breeder" ? "#BBBBD4" : "",
                  }}
                >
                  Hodowca
                </button>
                <button
                  className={styles.box__heroClassBtn}
                  onClick={() => {
                    handleChangeHeroClass("service_provider");
                    setEmailError("");
                    setBusinessError("");
                    setStateType("Województwo");
                    setImgs([]);
                  }}
                  style={{
                    transform:
                      heroClass === "service_provider"
                        ? "translateY(-10px) scale(1.05)"
                        : "",
                    backgroundColor:
                      heroClass === "service_provider" ? "#BBBBD4" : "",
                  }}
                >
                  Usługodawca
                </button>
                <button
                  className={styles.box__heroClassBtn}
                  onClick={() => {
                    handleChangeHeroClass("seller");
                    setDrop(false);
                    setEmailError("");
                    setBusinessError("");
                    setStateType("Województwo");
                    setImgs([]);
                  }}
                  style={{
                    transform:
                      heroClass === "seller"
                        ? "translateY(-10px) scale(1.05)"
                        : "",
                    backgroundColor: heroClass === "seller" ? "#BBBBD4" : "",
                  }}
                >
                  Sprzedawca
                </button>
              </div>
              <form className={styles.box__form} onSubmit={handleFormSubmit}>
                <h2 className={styles.box__formTitle}>
                  Rejestracja {headerName[heroClass]}{" "}
                  <span style={{ fontWeight: "normal" }}>(max: 1)</span>
                </h2>
                {heroClass === "breeder" && (
                  <>
                    <div className={styles.box__inputBox}>
                      <InputField
                        label={"Nazwa hodowli"}
                        name={"name"}
                        value={breederFormData.name}
                        onChange={handleBreederChange}
                        error={""}
                        type={"text"}
                        required
                        length={100}
                      />
                      <Dropdown
                        dropButtonName={breedType}
                        dropDownData={breedTypes}
                        setButtonName={setBreedType}
                        handleChange={handleBreederTypeChange}
                        changeType="type"
                        error={errorBreedType}
                      />
                    </div>
                    <div className={styles.box__inputBox}>
                      <InputField
                        label={"Adres email"}
                        name={"email"}
                        value={breederFormData.email}
                        onChange={handleBreederChange}
                        error={emailError}
                        type={"text"}
                        required
                        length={254}
                      />
                      <InputField
                        label={"Numer telefonu"}
                        name={"phone"}
                        value={breederFormData.phone}
                        onChange={handleBreederChange}
                        error={""}
                        type={"number"}
                        required
                        length={15}
                      />
                    </div>
                    <div className={styles.box__inputBox}>
                      <InputField
                        label={"Ulica"}
                        name={"street"}
                        value={breederFormData.street}
                        onChange={handleBreederChange}
                        error={""}
                        type={"text"}
                        required
                        length={100}
                      />
                      <InputField
                        label={"Numer budynku"}
                        name={"apartment"}
                        value={breederFormData.apartment}
                        onChange={handleBreederChange}
                        error={""}
                        type={"text"}
                        required={false}
                        length={10}
                      />
                    </div>
                    <div className={styles.box__inputBox}>
                      <InputField
                        label={"Kod pocztowy"}
                        name={"zipCode"}
                        value={breederFormData.zipCode}
                        onChange={handleBreederChange}
                        error={""}
                        type={"text"}
                        required
                        length={11}
                      />
                      <InputField
                        label={"Miasto"}
                        name={"city"}
                        value={breederFormData.city}
                        onChange={handleBreederChange}
                        error={""}
                        type={"text"}
                        required
                        length={58}
                      />
                    </div>
                    <div className={styles.box__inputBox}>
                      <Dropdown
                        dropButtonName={stateType}
                        dropDownData={statesTypes}
                        setButtonName={setStateType}
                        handleChange={handleBreederTypeChange}
                        changeType="state"
                        error={stateError}
                      />
                      <InputField
                        label={"Państwo"}
                        name={"country"}
                        value={breederFormData.country}
                        onChange={handleBreederChange}
                        error={""}
                        type={"text"}
                        required
                        length={56}
                      />
                    </div>
                    <InputField
                      label={"Opis hodowli"}
                      name={"description"}
                      value={breederFormData.description}
                      onChange={handleBreederChange}
                      error={""}
                      type={"text"}
                      required
                      length={1000}
                    />
                    {/* textarea zamiast tego */}
                  </>
                )}
                {heroClass === "service_provider" && (
                  <>
                    <div className={styles.box__inputBox}>
                      <InputField
                        label={"Nazwa usługi"}
                        name={"title"}
                        value={servProvFormData.title}
                        onChange={handleServProvChange}
                        error={""}
                        type={"text"}
                        required
                        length={100}
                      />
                      <Dropdown
                        dropButtonName={serviceType}
                        dropDownData={serviceTypes}
                        setButtonName={setServiceType}
                        handleChange={handleServProvTypeChange}
                        changeType="type"
                        error={errorServiceType}
                      />
                    </div>
                    <div className={styles.box__inputBox}>
                      <InputField
                        label={"Adres email"}
                        name={"email"}
                        value={servProvFormData.email}
                        onChange={handleServProvChange}
                        error={emailError}
                        type={"text"}
                        required
                        length={254}
                      />
                      <InputField
                        label={"Numer telefonu"}
                        name={"phone"}
                        value={servProvFormData.phone}
                        onChange={handleServProvChange}
                        error={""}
                        type={"number"}
                        required
                        length={15}
                      />
                    </div>
                    <div className={styles.box__inputBox}>
                      <InputField
                        label={"Ulica"}
                        name={"street"}
                        value={servProvFormData.street}
                        onChange={handleServProvChange}
                        error={""}
                        type={"text"}
                        required
                        length={100}
                      />
                      <InputField
                        label={"Numer budynku"}
                        name={"apartment"}
                        value={servProvFormData.apartment}
                        onChange={handleServProvChange}
                        error={""}
                        type={"text"}
                        required={false}
                        length={10}
                      />
                    </div>
                    <div className={styles.box__inputBox}>
                      <InputField
                        label={"Kod pocztowy"}
                        name={"zipCode"}
                        value={servProvFormData.zipCode}
                        onChange={handleServProvChange}
                        error={""}
                        type={"text"}
                        required
                        length={11}
                      />
                      <InputField
                        label={"Miasto"}
                        name={"city"}
                        value={servProvFormData.city}
                        onChange={handleServProvChange}
                        error={""}
                        type={"text"}
                        required
                        length={58}
                      />
                    </div>
                    <div className={styles.box__inputBox}>
                      <Dropdown
                        dropButtonName={stateType}
                        dropDownData={statesTypes}
                        setButtonName={setStateType}
                        handleChange={handleServProvTypeChange}
                        changeType="state"
                        error={stateError}
                      />
                      <InputField
                        label={"Państwo"}
                        name={"country"}
                        value={servProvFormData.country}
                        onChange={handleServProvChange}
                        error={""}
                        type={"text"}
                        required
                        length={56}
                      />
                    </div>
                    <InputField
                      label={"Opis usługi"}
                      name={"description"}
                      value={servProvFormData.description}
                      onChange={handleServProvChange}
                      error={""}
                      type={"text"}
                      required
                      length={1000}
                    />
                  </>
                )}
                {heroClass === "seller" && (
                  <>
                    <div className={styles.box__inputBox}>
                      <InputField
                        label={"Nazwa sklepu"}
                        name={"name"}
                        value={sellerFormData.name}
                        onChange={handleSellerChange}
                        error={""}
                        type={"text"}
                        required
                        length={100}
                      />

                      <Dropdown
                        dropButtonName={sellerType}
                        dropDownData={sellerTypes}
                        setButtonName={setSellerType}
                        handleChange={handleSellerTypeChange}
                        changeType="type"
                        error={errorSellerType}
                      />
                    </div>
                    <div className={styles.box__inputBox}>
                      <InputField
                        label={"Adres email"}
                        name={"email"}
                        value={sellerFormData.email}
                        onChange={handleSellerChange}
                        error={emailError}
                        type={"text"}
                        required
                        length={254}
                      />
                      <InputField
                        label={"Numer telefonu"}
                        name={"phone"}
                        value={sellerFormData.phone}
                        onChange={handleSellerChange}
                        error={""}
                        type={"number"}
                        required
                        length={15}
                      />
                    </div>
                    <div className={styles.box__inputBox}>
                      <InputField
                        label={"Ulica"}
                        name={"street"}
                        value={sellerFormData.street}
                        onChange={handleSellerChange}
                        error={""}
                        type={"text"}
                        required={false}
                        length={100}
                      />
                      <InputField
                        label={"Numer budynku"}
                        name={"apartment"}
                        value={sellerFormData.apartment}
                        onChange={handleSellerChange}
                        error={""}
                        type={"text"}
                        required={false}
                        length={10}
                      />
                    </div>
                    <div className={styles.box__inputBox}>
                      <InputField
                        label={"Kod pocztowy"}
                        name={"zipCode"}
                        value={sellerFormData.zipCode}
                        onChange={handleSellerChange}
                        error={""}
                        type={"text"}
                        required={false}
                        length={11}
                      />
                      <InputField
                        label={"Miasto"}
                        name={"city"}
                        value={sellerFormData.city}
                        onChange={handleSellerChange}
                        error={""}
                        type={"text"}
                        required={false}
                        length={58}
                      />
                    </div>
                    <div className={styles.box__inputBox}>
                      <Dropdown
                        dropButtonName={stateType}
                        dropDownData={statesTypes}
                        setButtonName={setStateType}
                        handleChange={handleSellerTypeChange}
                        changeType="state"
                        error={stateError}
                      />
                      <InputField
                        label={"Państwo"}
                        name={"country"}
                        value={sellerFormData.country}
                        onChange={handleSellerChange}
                        error={""}
                        type={"text"}
                        required={false}
                        length={56}
                      />
                    </div>
                    <InputField
                      label={"Adres strony sklepu"}
                      name={"link"}
                      value={sellerFormData.link}
                      onChange={handleSellerChange}
                      error={""}
                      type={"text"}
                      required={false}
                      length={2048}
                    />
                    <InputField
                      label={"Opis sklepu"}
                      name={"description"}
                      value={sellerFormData.description}
                      onChange={handleSellerChange}
                      error={""}
                      type={"text"}
                      required
                      length={1000}
                    />
                  </>
                )}
                <div className={styles.box__bottomContentBox}>
                  <div className={styles.box__buttonBox}>
                    <button
                      className={styles.box__button}
                      type="submit"
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <Loading></Loading>
                      ) : (
                        <>
                          <span>Z</span>
                          <span>a</span>
                          <span>r</span>
                          <span>e</span>
                          <span>j</span>
                          <span>e</span>
                          <span>s</span>
                          <span>t</span>
                          <span>r</span>
                          <span>u</span>
                          <span>j</span>{" "}
                          {buttonName[heroClass]
                            .split("")
                            .map((letter: any, i: number) => (
                              <span key={i}>{letter}</span>
                            ))}
                        </>
                      )}
                    </button>
                  </div>
                  <div className={styles.box__inputImgBox}>
                    <div className={styles.box__inputImgContainer}>
                      <p className={styles.box__inputImgHeader}>
                        Dodaj zdjęcia
                        <span style={{ fontWeight: "normal" }}> (max: 3)</span>
                      </p>
                      <label className={styles.box__fileInputLabel}>
                        <input
                          className={styles.box__fileInput}
                          type="file"
                          multiple
                          max={3}
                          onChange={onImageChange}
                          accept="image/png, image/jpg, image/jpeg"
                        />
                        Przeglądaj...
                      </label>
                      <div className={styles.box__filesNames}>
                        {imgs && (
                          <div className={styles.box__fileList}>
                            {Array.from(imgs).map((file, index) => (
                              <div key={index}>{file.name}, </div>
                            ))}
                          </div>
                        )}
                      </div>
                      <button
                        className={styles.box__deleteFiles}
                        onClick={onClick}
                      >
                        Usuń dodane pliki
                      </button>
                    </div>

                    <label className={styles.box__inputImgError}>
                      {imgError}
                    </label>
                  </div>
                  {/* <RelativeInfobox /> */}
                </div>
              </form>
            </div>
            <div className={styles.box__contentBox}>
              <p className={styles.box__info}>
                Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                Incidunt, enim nam dignissimos praesentium temporibus aliquam?
              </p>
              <p className={styles.box__info}>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Doloremque, maiores!
              </p>
              <div className={styles.box__imgBox}>
                <img
                  className={styles.box__img}
                  src={imgType[heroClass]}
                  alt="zdjęcie zwierzaka"
                />
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    </Page>
  );
}
