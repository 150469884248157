import React from "react";
import { Link } from "react-router-dom";
import styles from "./button.module.scss";

interface Button {
  children?: React.ReactNode;
  route: string;
}

export default function Button({ children, route }: Button) {
  return (
    <Link to={route} className={styles.linkStyle}>
      <div className={styles.button}>{children}</div>
    </Link>
  );
}
