import { useState } from "react";
import styles from "./dropdown.module.scss";
interface DropdownInterface {
  dropButtonName: string;
  dropDownData: any;
  setButtonName: any;
  handleChange?: any;
  changeType?: string;
  filterType?: string;
  handleFilterChange?: any;
  error?: string;
}
export default function Dropdown({
  dropButtonName,
  dropDownData,
  setButtonName,
  handleChange,
  changeType,
  filterType,
  handleFilterChange,
  error,
}: DropdownInterface) {
  const [drop, setDrop] = useState<boolean>(false);
  return (
    <div className={styles.boxDrop}>
      <button
        onClick={() => setDrop(!drop)}
        type="button"
        className={`${styles.boxDrop__dropBtn} ${
          error ? styles.boxDrop__dropBtn__error : ""
        }`}
      >
        {dropButtonName}
      </button>
      {/* <label className={styles.formLabelName}>{dropButtonName}</label> */}
      <div
        className={styles.boxDrop__dropdownBox}
        style={{
          transform: drop
            ? "translateY(100%) scaleY(1)"
            : "translateY(100%) scaleY(0)",
          visibility: drop ? "visible" : "hidden",
          opacity: drop ? "1" : "0",
        }}
      >
        {dropDownData.map((item: any, index: string) => (
          <button
            onClick={() => {
              setDrop(false);
              if (handleChange && changeType === "type")
                handleChange("type", item);
              if (handleChange && changeType === "state")
                handleChange("state", item);
              if (handleFilterChange && filterType === "state")
                handleFilterChange("state", item);
              if (handleFilterChange && filterType === "type")
                handleFilterChange("type", item);
              setButtonName(item);
            }}
            type="button"
            className={styles.boxDrop__dropdownBtn}
            key={item}
          >
            {item}
          </button>
        ))}
      </div>
      {error && <p className={styles.errorMessage}>{error}</p>}
    </div>
  );
}
