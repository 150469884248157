import React, { useState } from "react";
import DeleteBusiness from "./DeleteBusiness";
import styles from "./deleteBusiness.module.scss";

interface DeleteBusinessButtonProps {
  label: string;
}

export default function DeleteBusinessButton({
  label,
}: DeleteBusinessButtonProps) {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  return (
    <>
      <button onClick={togglePopup} className={styles.popupButton}>
        Usuń działalność
      </button>
      <DeleteBusiness isOpen={isPopupOpen} onClose={closePopup} label={label} />
    </>
  );
}
