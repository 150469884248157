import ScrollToTop from "components/navigation/ScrollToTop";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";
import { UserProviders } from "./context/UserContext";
import Router from "routing/Router";
function App() {
  return (
    <AuthProvider>
      <UserProviders>
        <BrowserRouter>
          <ScrollToTop />
          <Router />
        </BrowserRouter>
      </UserProviders>
    </AuthProvider>
  );
}

export default App;
